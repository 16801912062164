export default {
  common: {
    noData: 'No data',
    noDataFound: 'No data found',
    topic: 'Topic',
    noTopic: 'No topic',
    noTopicSelected: 'No topic selected',
    noFile: 'No file found',
    noFiles: 'No files available',
    deleteFile: 'Delete file',
    cancel: 'Cancel',
    save: 'Save',
    required: 'Required field',
    requiredShort: 'Required',
    requiredInvalid: 'Required field must be filled',
    fileTooLarge: 'File too large. Maximum {size} MB allowed',
    inputTooLong: 'Input maximum {n} characters',
    send: 'Send',
    edit: 'Edit',
    delete: 'Delete',
    repeat: 'Repeat',
    doRepeat: 'Repeat',
    repetition: 'Repetition',
    close: 'Close',
    serverError: 'A server error occurred',
    unnamed: 'Unnamed',
    serverConnectionError: 'Could not connect to the server',
    contact: 'Contact',
    imprint: 'Imprint',
    privacy: 'Privacy',
    question: 'Question',
    password: 'Password',
    showAll: 'Show all',
    upload: 'Upload',
    start: 'Start',
    startTime: 'Start time',
    duration: 'Duration',
    customer: 'Customer',
    company: 'Company',
    username: 'Username',
    limit: 'Limit',
    type: 'Type',
    size: 'Size',
    action: 'Action',
    actions: 'Actions',
    settings: 'Settings',
    participants: 'Participants',
    moderators: 'Moderators',
    active: 'Active',
    inactive: 'Inactive',
    activated: 'Activated',
    deactivated: 'Deactivated',
    answers: 'Answers',
    preview: 'Preview',
    all: 'All',
    end: 'End',
    sidebar: 'Sidebar',
    services: 'Services',
    inWork: 'In work',
    conference: 'Conference',
    share: 'Share',
    german: 'German',
    english: 'English',
    sendMail: 'Send mail',
    sendTestMail: 'Send test mail',
    responseAdress: 'Response email',
    attachments: 'Attachments',
    select: 'Select',
    changeSelection: 'Change selection',
    invertSelection: 'Invert selection',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    name: 'Name',
    phone: 'Phone number',
    phoneShort: 'Phone',
    phoneNumber: 'Phone number',
    phoneMobile: 'Phone (mobile)',
    fax: 'Fax',
    status: 'Status',
    recording: 'Recording',
    recordings: 'Recordings',
    deleteAfter: 'Auto-delete after {days} days',
    download: 'Download',
    email: 'Email',
    add: 'Add',
    language: 'Language',
    none: 'None',
    none2: 'None',
    begin: 'Begin',
    until: 'Until',
    from: 'From',
    to: 'To',
    summary: 'Summary',
    back: 'Back',
    notice: 'Notice',
    confirm: 'Confirm',
    optional: 'Optional',
    phoneInvalid: 'Please check the phone number',
    numberExists: 'Phone number already exists',
    emailInvalid: 'Please check the email',
    emailExists: 'Email already exists',
    dateCreated: 'Creation date',
    selectLanguage: 'Select language',
    atThe: 'at the',
    at: 'at',
    dialIn: 'Dial-In',
    selectFile: 'Select file',
    verify: 'Verify',
    import: 'Import',
    or: 'or',
    minutes: 'Min',
    nMinutes: '{number} Min',
    minutesLong: 'Minutes',
    hours: 'Hours',
    survey: 'Survey',
    appointment: 'Appointment',
    appointmentDate: 'Date',
    registered: 'Registered',
    expected: 'Expected',
    fullname: 'Full name',
    date: 'Date',
    time: 'Time',
    preAppointment: 'Pre-appointment',
    preAppointmentShort: 'Pre-apt.',
    noConference: 'No conference',
    regForm: 'Registration form',
    regForms: 'Registration forms',
    surveyForm: 'Survey form',
    surveyForms: 'Survey forms',
    presentations: 'Presentations',
    management: 'Management',
    room: 'Conference room',
    rooms: 'Conference rooms',
    control: 'Control',
    brandings: 'Brandings',
    salutation: 'Salutation',
    title: 'Title',
    firstname: 'First name',
    lastname: 'Last name',
    street: 'Street',
    streetAndNumber: 'Street and number',
    streetAndNumberShort: 'Street and number',
    zipCode: 'ZIP code',
    zip: 'ZIP',
    city: 'City',
    zipCodeAndCity: 'ZIP code and city',
    zipAndCity: 'ZIP and city',
    country: 'Country',
    timezone: 'Timezone',
    mr: 'Mr.',
    mrs: 'Mrs.',
    diverse: 'Other',
    divers: 'Diverse',
    costCenterPreset: 'Cost center preset',
    playback: 'Playback',
    reset: 'Reset',
    overview: 'Overview',
    form: 'Form',
    forms: 'Forms',
    backToOverview: 'Back to overview',
    confirmed: 'Confirmed',
    total: 'Total',
    setup: 'Setup',
    exists: 'Exists',
    points: 'Points',
    percent: 'Percent',
    orMoveHere: 'or move here',
    untilAround: 'until around',
    approx: 'appx. {value}',
    branding: 'Branding',
    keep: 'Keep',
    url: 'URL',
    description: 'Description',
    postCodeAndCity: 'ZIP code and city',
    postCodeCity: 'ZIP & city',
    notes: 'Notes',
    on: 'On',
    partnerID: 'Partner ID',
    callNumber: 'Call number',
    unsaved: 'Unsaved',
    imageSize: '{x} x {y} Pixels',
    requiredInvalidAndMail: 'Required field must be filled and be an email.',
    vvId: 'VV-ID',
    comment: 'Comment',
    locked: 'Locked',
    id: 'ID',
    created: 'Created',
    info: 'Info',
    createdAt: 'Created at',
    lock: 'Lock',
    unlock: 'Unlock',
    requiredInvalid2: 'Required field, please fill out',
    verifyRedFields: 'Check the red-marked fields.',
    anErrorOccurredTryAgain: 'An error occurred, please try again.',
    anErrorOccurredReloadPage: 'An error occurred, please try again. Or reload the page',
    emailAddress: 'Email address',
    unlimited: 'Unlimited',
    products: 'Products',
    dialInEn: 'Dial-In',
    dialOut: 'Dial-Out',
    no: 'No',
    yes: 'Yes',
    today: 'Today',
    numberShort: 'No.',
    options: 'Options',
    gutBeraten: 'Gut beraten',
    and: 'and',
    examples: 'examples',
    w2Access: 'W2 access',
    speakerProfile: 'Cards / LV-dialogue',
    liveVideoBackground: 'Video backgrounds',
    presentation: 'Presentations',
    pinReservation: 'PIN reservation',
    address: 'Address'
  },
  nav: {
    dashboard: 'Dashboard',
    menu: 'Menu',
    logout: 'Log Out',
    logoutOperator: 'Logout Operator',
    gotAnyQuestions: 'Got any questions? Call us.',
    csnDescription: 'CSN Conferencing is a conference service provided by CSN GmbH',
    forms: 'Forms',
    conferences: 'Conferences',
    overviewRegForms: 'Registration Forms',
    overviewSurveyForms: 'Survey Forms',
    presentation: 'Presentations',
    bookPresentation: 'Book a Presentation',
    businessCards: 'Business cards',
    service: 'Service',
    operatorServices: 'Operator Services',
    userManagement: 'User Management',
    report: 'Report',
    registrations: 'Registrations',
    surveys: 'Surveys',
    system: 'System',
    admin: 'Admin',
    news: 'News',
    blacklist: 'Participant Blacklist',
    pinBlacklist: 'PIN Blacklist',
    profile: 'Profile',
    supportCenter: 'Support Center',
    serviceCenter: 'Service Center',
    login: 'Login',
    loginAs: 'Login as {username}',
    customFields: 'Custom input fields',
    liveVideoBackgrounds: 'Video backgrounds',
    pinReservation: 'Pin reservation',
    waitingRoomBrandings: 'Waiting room brandings',
    descriptions: {
      scheduledConferences: 'Conference Overview',
      plannedConferences: 'Conference Overview',
      newConference: 'Book Conference',
      roomConferences: 'Manage Conference Rooms',
      newConferenceRoom: 'Book Conference Room',
      dashboard: 'Home',
      overviewRegForms: 'participant management',
      newRegForm: 'Create Registration Form',
      overviewSurveyForms: 'Tests & votes',
      newSurveyForm: 'Create Survey Form',
      customField: 'optionally with validation',
      brandings: 'In your own design',
      newBranding: 'Create Branding',
      operatorServices: 'Booking Overview',
      bookOperator: 'Request Operator Service',
      adressbook: 'Contacts & Groups',
      playbacks: 'Manage Audio Files',
      runningConferencesAdmin: 'Operator Console',
      scheduledConferencesAdmin: 'Conference Planning',
      pastConferencesAdmin: 'Conference History',
      registrations: 'Participant Overview',
      surveys: 'Survey Overview',
      news: 'Manage News (JSON)',
      dialNumbers: 'Manage Numbers',
      blacklist: 'Block Participants',
      pinBlacklist: 'Block PINs',
      profile: 'Account & Security',
      supportCenter: 'Manuals & Videos',
      serviceCenter: 'Brandings, Playbacks, Video Editing ...',
      presentation: 'Link to visual part',
      presentationNew: 'create and manage',
      businessCards: 'For speakers',
      liveVideoBackgrounds: 'Custom backgrounds',
      pinReserveation: 'Exclusive PINs',
      waitingRoomBrandings: 'Custom waiting rooms'
    }
  },
  home: {
    plannedAppointment: 'Planned Appointment',
    plannedAppointments: 'Planned Appointments',
    registeredUsers: 'Registered Participants',
    activeForm: 'Active Form',
    activeForms: 'Active Forms',
    createConference: 'Create a new conference',
    newConference: 'New Conference',
    createRegForm: 'Create a new registration form',
    newRegForm: 'New Registration Form',
    anytimeCallable: 'Callable anytime'
  },
  profile: {
    videoTutorials: 'Video Tutorials',
    handbooks: 'Handbooks',
    markAllRead: 'Mark all as read',
    markRead: 'Mark as read',
    markUnread: 'Mark as unread',
    notifications: 'Notifications',
    accountAndSecurity: 'Account & Security',
    infoAndSecurity: 'Info & Security',
    customerNr: 'Customer number',
    deadline: 'Deadline',
    flatrate: 'Flatrate',
    changePassword: 'Change Password',
    contactPartner: 'Contact person',
    confSetup: 'Conference Setup',
    wizard: 'Booking Wizard',
    doi: 'Double-Opt-In',
    callTries: 'Call Tries',
    callDistance: 'Call Distance',
    products: 'Product Approvals',
    personalData: 'Personal Data',
    contactData: 'Contact Data',
    account: 'Account',
    profileUpdated: 'Account successfully updated!',
    settings: {
      changeNavigationColor: 'Change Navigation Color:',
      anyColor: 'Any Color:',
      selectSpecificColor: 'Select Specific Color: (first color is default color)',
      changeHeaderColor: 'Change Header Color:',
      selectHeaderColorDefault: 'Select Header Color: (first color is default color)',
      selectTextColorDefault: 'Select Text Color: (first color is default color)',
      changePrimaryColor: 'Change Primary Color:',
      changeBackgroundColor: 'Change Background Color:',
      changeCardColor: 'Change Card Color:',
      changeNewsColor: 'Change News Color:',
      selectTextColor: 'Select Text Color:',
      changeFooterColor: 'Change Footer Color:',
      toggleBorderColon: 'Toggle Border:',
      toggleBorder: 'Toggle Border'
    },
    passwordExpires: 'Your password expires today. Click here to change your password. | Your password expires tomorrow. Click here to change your password. | Your password expires in {count} days. Click here to change your password. ',
    passwordExpired: 'Your password has expired. You will not be able to log in again until you change your password. Click here to change your password.'
  },
  adressbook: {
    adressbook: 'Address Book',
    adressbookFileName: 'Addressbook',
    allContacts: 'All Contacts',
    selectGroups: 'Select Address Book Groups',
    selectedContacts: 'Selected Contacts',
    addedSuccessfully: 'Participants with phone number successfully added to the address book',
    group: 'Group',
    groups: 'Groups',
    pleaseSelectGroup: 'Please select a group',
    addContacts: 'Add Contacts',
    contacts: 'Contacts',
    addAsModerator: 'Add as Moderator',
    asModerator: 'As Moderator',
    addAsParticipant: 'Add as Participant',
    asParticipant: 'As Participant',
    noGroup: 'No Group',
    noContacts: 'No Contacts',
    selectGroup: 'Select Group',
    newGroup: 'New Group',
    importContacts: 'Import Contacts',
    exportContacts: 'Export Contacts',
    emptyAdressbook: 'Empty Address Book',
    import: {
      description1: 'You can upload contacts as an Excel (XLSX) or CSV file and import them into your address book.',
      description2: 'The file must contain at least the name and a phone number for each contact.',
      description3: 'The contacts will be added to the selected group.',
      description4: 'Map the fields of a contact (left) to the columns to be imported from the XLSX/CSV file (right):',
      dataChecked: 'Your data has been checked. You can now start the import of your data.',
      coding: 'Coding of the XLSX/CSV file',
      validData: 'Valid Records',
      invalidData: 'Invalid Records',
      duplettes: 'Duplicates',
      deletedData: 'Deleted Records',
      data: 'Records',
      correctAndVerify: 'Correct or verify your records and confirm afterwards.',
      checkExcel: 'Check the Excel file. Only one worksheet is allowed.',
      dataImported: 'Data successfully imported!',
      selectNameAndPhone: 'Please select name and phone number fields'
    },
    groupSelection: 'Group Assignment',
    pullCsvHere: 'Drag XLSX/CSV file here',
    emailShown: 'Email visible',
    emailUnshown: 'Email invisible',
    companyShown: 'Company visible',
    companyUnshown: 'Company invisible',
    newContact: 'Create New Contact',
    inGroup: 'in Group "{group}"',
    addToGroup: 'Add to Group',
    contactAdded: 'Contact successfully added!',
    contactDeleted: 'Contact successfully deleted!',
    contactsDeleted: 'Contacts successfully deleted!',
    allContactsDeleted: 'All contacts successfully deleted!',
    allGroupsDeleted: 'All groups successfully deleted!',
    groupExists: 'Group already exists',
    groupAdded: 'Group successfully added!',
    groupDeleted: 'Group successfully deleted!',
    groupNameChanged: 'Group name successfully changed!',
    noContactsToExport: 'No contacts available for export',
    excelFileError: 'The Excel file cannot be imported. This could have various reasons, such as hidden columns, multiple worksheets, macros, ...\n' +
      'Please clean up the file and save the document under a different name. Re-saving the file also fixes errors if the file is corrupted.'
  },
  audio: {
    add: 'Add Audio File',
    select: 'Select Audio File',
    orMoveHere: 'or move here',
    max: 'maximum 100 MB',
    files: 'Audio Files',
    downloadPlayback: 'Download Playback',
    uploaded: 'Audio file uploaded successfully!',
    deleted: 'Audio file deleted successfully!'
  },
  admin: {
    users: 'Users',
    customers: 'Customers',
    admins: 'Administrators',
    manageUser: 'Manage User',
    manageCustomer: 'Manage Customer',
    manageAdmins: 'Manage Administrators',
    editCustomers: 'Edit Customers',
    editUser: 'Edit User',
    showConfDetails: 'Show Conference Details',
    registrationsCount: '{users} participants, distributed over {dates} dates',
    noCustomer: 'No Customers',
    allCustomers: 'All Customers',
    loginAsUser: 'Login as {username}',
    login: 'Login',
    w2MatchingStatus: 'Matched',
    w2Matching: {
      noMatch: 'No match',
      matchWithPermission: 'Match with permission',
      matchWithoutPermission: 'Match without permission'
    },
    customersCantLoad: 'Customers could not be loaded.',
    deleteAdminModal: {
      title: 'Delete Administrator',
      message: 'Do you really want to delete the administrator {name} ({username})?',
      deleted: 'Administrator successfully deleted'
    },
    deleteCustomerModal: {
      title: 'Delete Customer',
      message: 'Do you really want to delete the customer "{title}"?',
      deleted: 'Customer successfully deleted'
    },
    errorOnDeactivation: 'Error on deactivation',
    errorOnActivation: 'Error on activation',
    editAdmin: 'Edit Administrator',
    lastLogin: 'Last Login',
    deactivationReason: 'Reason: {reason}',
    permissions: 'Access Rights',
    customerManagement: 'Customer Management',
    userManagement: 'User Management',
    loginAs: 'Login as',
    operatorConsole: 'Operator Console',
    billing: 'Billing',
    conferenceHistory: 'Conference History',
    systemConfiguration: 'System Configuration',
    numberManagement: 'Number Management',
    saveDescription: 'Save Description',
    userAccount: 'User Account',
    failedLogins: 'failed login attempt | failed login attempts',
    passwordRequirements: 'Password must be at least 8 characters, include uppercase and lowercase letters, at least one digit, and one special character.',
    passwordRequirements2: 'Password must be at least eight characters, include numbers, uppercase letters, and special characters.',
    timeLimit: 'Time Limit',
    none: 'None',
    to: 'To',
    passwordPolicyChangeAfterLogin: 'Change after first login / reset',
    passwordPolicyChangeAfterLoginAndNDays: 'Change after first login / reset / every {n} days',
    passwordPolicy: 'Password Policy',
    descriptionChangedSuccessfully: 'Description changed successfully!',
    permissionChanged: 'Access right changed successfully!',
    adminUpdated: 'Administrator successfully updated!',
    passwordNotFollowingGuidelines: 'Password does not follow guidelines',
    usernameAlreadyTaken: 'Username is already taken',
    createAdmin: 'Create Administrator',
    notes: 'Notes',
    commentsForNotes: 'Comment field for notes about the administrator.',
    selectSalutation: 'Please select a salutation',
    formContainsErrors: 'The form contains at least one error. Please check your entries and correct the fields marked in red.',
    userAndDescriptionCreated: 'User successfully added!',
    userDeleted: 'User successfully deleted',
    usernameExists: 'Username is already taken.',
    exportAsXlsx: 'Export as xlsx',
    userLimitExceeded: 'Action not possible. User limit reached.',
    userLimitExceededShort: 'User limit reached',
    customerHasUsers: 'Action not possible. Customer has users.',
    createUser: 'Create User',
    customerNumberShort: 'Cust. No.',
    participantLimitShort: 'Flat',
    editCustomer: 'Edit Customer',
    createdBy: 'created by',
    active: 'Active',
    inactive: 'Inactive',
    brandingsAfUf: 'Brandings (AF/UF)',
    showContaktDetailInAccount: 'Show contact details in user account',
    mustBeMail: 'Must be an email.',
    invoiceRecipient: 'Invoice Recipient',
    billingCustomer: 'Billing Customer',
    testCustomer: 'Test Customer',
    likeContactPerson: 'Like Contact Person',
    careOfShort: 'c/o',
    payment: 'Payment',
    onBill: 'On Bill',
    accountOwner: 'Account Owner',
    iban: 'IBAN',
    ibanInvalid: 'Please enter a valid IBAN',
    bic: 'BIC (Swift-Code)',
    instituteAuto: 'Institute (automatic entry)',
    customerType: 'Customer Type',
    individualConnectionProof: 'Individual Connection Proof',
    egn: 'ICP',
    userLimit: 'User Limit',
    maxUsers: 'Max. {number} Users',
    maxParticipants: 'Max. {number} Participants',
    participantLimit: 'Participant Flatrate',
    weblinkToPresentation: 'Weblink to Presentation',
    numberShorted: 'Target number shortened by 3 digits',
    unshorted: 'Unshortened',
    productChanged: 'Product addition changed successfully!',
    customerChanged: 'Customer successfully updated!',
    createCustomer: 'Create Customer',
    insertCompany: 'Enter Company Name',
    addCustomer: 'Add Customer',
    customerAndDescriptionAdded: 'Customer and description added successfully!',
    customerAdded: 'Customer added successfully!',
    editDescription: 'Edit Description',
    numbers: {
      manageNumbers: 'Manage Numbers',
      bridgeNumber: 'Bridge Number',
      bridgeSetup: 'Bridge Setup',
      displayNumber: 'Display Number',
      displayTitle: 'Display Title',
      display: 'Display',
      lindenbaumDescription: 'Lindenbaum Description',
      insertNewDisplayNumber: 'Enter new display number from {number}',
      insertNewDescription: 'Enter new description from {number}',
      newDisplayNumber: 'New Display Number',
      newDescription: 'New Description'
    },
    operator: {
      nOperatorServices: '{number} Operator Services',
      plannedPastConferences: '{planned} planned / {passed} past conferences',
      loginAsUser: 'Login as User',
      assignOperator: 'Assign Operator',
      contactOrganizer: 'Contact Organizer',
      cancelBooking: 'Cancel Booking'
    },
    operatorServices: 'Operator Services',
    noUsers: 'No Users',
    cantLoad: 'Accounts could not be loaded.',
    customerhasNoProducts: 'Customer has no products',
    createDelete: 'create/delete',
    changeFlat: 'Change Flatrate',
    changeBillingDetails: 'Change Billing Details',
    changeContactPartner: 'Change Contact Partner',
    userStats: 'Usage Statistics',
    mediaLibrary: 'Media Library',
    globalBrandings: 'Global Brandings',
    globalInputFields: 'Global input fields',
    createUserLong: 'Create User',
    addUser: 'Add User',
    plsSelectCustomer: 'Please select a customer',
    insertUsername: 'Enter username',
    notesForUser: 'Comment field for notes about the user',
    customerDefault: 'Customer default',
    selectCustomer: 'Select Customer',
    nDaysPreview: '{n} days preview',
    perDay: '/day',
    systemload: 'System Load',
    excel: {
      dbId: 'DB ID',
      dbCustomerId: 'DB Customer ID',
      participantLimit: 'Participant Limit',
      dialOutRetryCount: 'Dial-Out Retry Count',
      dialOutRetryDelay: 'Dial-Out Retry Delay',
      blacklistEmail: 'Blacklist Email',
      blacklistPhone: 'Blacklist Phone',
      passwordPolicy: 'Password Policy',
      role: 'Role',
      accountSettings: 'Account Settings',
      deactivationReason: 'Deactivation Reason',
      failedLogins: 'Failed Logins',
      lindenbaumId: 'Lindenbaum ID',
      lindenbaumUsername: 'Lindenbaum Username',
      lindenbaumLastSync: 'Last Synchronization Lindenbaum',
      regFormShort: 'RF',
      surveyFormShort: 'SF',
      bankName: 'Bank Name',
      egnShorted: 'EGN Shortened',
      showContactDetailInAccount: 'Show Contact Details in Accounts',
      presentationUrl: 'Presentation URL',
      deleted: 'Deleted',
      asp: 'ASP',
      rg: 'RG'
    },
    invalidUrl: 'Not a valid url',
    invalidUrlLogin: 'The URL must not end with \'login\'.',
    surveys: {
      headerSubtitle: '{totalRows} active surveys with {participants} participants',
      noSurveys: 'No surveys',
      activeForms: 'Active forms',
      allForms: 'All forms',
      lastParticipantShort: 'last part.',
      questionsShort: 'Qs.',
      surveysCantLoad: 'Surveys could not be loaded',
      noCert: 'No certificate available',
      certUploaded: 'Certificate uploaded',
      certSent: 'Certificate sent',
      evaluationActive: 'Evaluation active',
      noEvaluation: 'No Evaluation active'
    },
    userExcelExport: 'User Excel Export',
    customerExcelExport: 'Customer Excel Export',
    passwordExpiration: 'Password expiration',
    afterMonths: 'after one month | after {count} months'
  },
  conference: {
    conferences: 'Conferences',
    runningConferences: 'Running Conferences',
    runningConference: 'Running Conference',
    countRunningConferences: '{count} Running Conferences | 1 Running Conference | {count} Running Conferences',
    OneRunningConference: 'Running Conference',
    scheduledConferences: 'Scheduled Conferences',
    scheduledConference: 'Scheduled Conference',
    countScheduledConferences: '{count} Scheduled Conferences | 1 Scheduled Conference | {count} Scheduled Conferences',
    pastConferences: 'Past Conferences',
    pastConference: 'Past Conference',
    countPastConferences: '{count} Past Conferences | 1 Past Conference | {count} Past Conferences',
    newConference: 'New Conference',
    editConference: 'Edit Conference',
    bookConference: 'Book Conference',
    bookNow: 'Book now',
    roomConferences: 'Anytime Conference Rooms',
    newConferenceRoom: 'New Conference Room',
    costCenter: 'Cost Center',
    costCenterShort: 'CC',
    noCostCenter: 'No Cost Center',
    chooseCostCenter: 'Choose Cost Center',
    newCostCenter: 'New Cost Center',
    seriesDate: 'Series Date',
    series: 'Series',
    playback: 'Playback',
    live: 'Live',
    liveVideo: 'Live Video',
    showOperatorBooking: 'Show Operator Booking',
    editOperatorBooking: 'Edit Operator Booking',
    noOperatorBooked: 'No operator booked',
    bookOperator: 'Book Operator Service',
    operator: 'Operator',
    operatorShort: 'OP',
    moderatorPin: 'Moderator PIN',
    moderatorPinShort: 'Mod PIN',
    participantPin: 'Participant PIN',
    participantPinShort: 'Part PIN',
    pinShort: 'PIN (Mod/Part)',
    pinShort2: 'MP/TP',
    pinLong: 'PIN (Moderator - Participant)',
    pin: 'PIN',
    moderator: 'Moderator',
    moderatorShort: 'Mod',
    participant: 'Participant',
    participantShort: 'Part',
    registeredUsersShort: 'PT',
    unlimited: 'Unlimited',
    conferenceOptions: 'Conference Settings',
    audioOptions: 'Audio Settings',
    options: {
      schooling: 'Training Mode (Participants muted)',
      schoolingDescription: 'Raise hands via phone *1 possible',
      waitingRoom: 'Waiting Room for Dial-In and Dial-Out Participants',
      waitingRoomDescription: 'Allows for a pre-discussion with speakers',
      endOnModHangup: 'End Conference when Moderator hangs up',
      endOnModHangupDescription: 'Web control is recommended',
      autoPlayback: 'Automatically start recording',
      autoPlaybackDescription: 'If necessary inform participants about the recording',
      beepOnAdd: 'Beep when participant joins',
      beepOnAddDescription: 'Recommended only for small conferences',
      beepOnEnd: 'Beep when participant hangs up',
      activeConfirmation: 'Active confirmation on call',
      activeConfirmationDescription: 'Prevents recording by voicemail',
      functionNotAvailable: 'Function temporarily unavailable',
      notAvailableInPlayback: 'Not available in playback conferences'
    },
    noRegForm: 'No registration form',
    noSurveyForm: 'No survey form',
    assignForm: 'Assign',
    assignRegForm: 'Assign Registration Form',
    assignSurveyForm: 'Assign Survey Form',
    selectedConference: 'Selected Conference',

    endConference: 'End Conference',
    deleteConference: 'Delete Conference',

    controlPanel: 'Control Panel',
    openControlPanel: 'Open Control Panel',
    showConfirmation: 'Show Booking Confirmation',
    confirmationAndServices: 'Booking Confirmation & Services',
    startImmediately: 'Start Immediately',
    dialNumbers: 'Dial-In Numbers',
    dialNumbersTitle: 'National Dial-In and Service Numbers',
    useAsTemplate: 'Use as Template',
    conferenceDetails: 'Conference Details',
    downloadRecording: 'Download Recording',

    chooseCountry: 'Choose Country',
    playbacks: 'Playbacks',
    noPastConference: 'No past conferences',
    noRunningConference: 'No running conferences',
    noScheduledConference: 'No scheduled conferences',
    liveConference: 'Live Conference',
    recording: 'Recording',
    rec: 'Rec',
    dialout: 'Dial Out',
    dialin: 'Dial In',
    conferenceEnded: 'Conference Ended',
    conferenceEndConfirm: 'Do you really want to end this conference?',
    functions: 'Functions',
    bookedFunctions: 'Booked Functions',
    conferenceStarted: 'Conference successfully started!',
    conferenceDeleted: 'Conference successfully deleted!',
    seriesDeleted: 'Conference series successfully deleted!',
    bookingConfirmation: 'Booking Confirmation',

    mailToParticipants: 'Mail to Participants',
    downloadConfirmation: 'Download Confirmation',
    downloadDetails: 'Download Conference Details',
    downloadExpandedDetails: 'Download Extended Conference Details',
    noConference: 'No conference available',

    event: 'Event',
    eventType: 'Event Type',
    settings: 'Conference and Audio Settings',
    dialInConference: 'Dial-In to Conference',
    moreDialNumbers: 'More Dial Numbers',
    sections: {
      languageInEmails: 'Language in Emails and Announcements',
      eventType: 'Event Type',
      mode: 'Mode',
      costCenter: 'Cost Center',
      waitingRoom: 'Waiting Room',
      conferenceEnd: 'Conference End',
      recording: 'Recording',
      tone: 'Tone',
      confirmation: 'Confirmation on Call',
      modPin: 'Moderator PIN',
      partPin: 'Participant PIN',
      pin: 'Moderator PIN / Participant PIN',
      regForm: 'Registration Form',
      surveyForm: 'Survey Form',
      dialNumbers: 'Dial-In Numbers',
      language: 'Language'
    },
    sectionValues: {
      schooling: 'Training (Participants muted)',
      talk: 'Meeting',
      activated: 'Activated',
      deactivated: 'Deactivated',
      whenModEnds: 'When Moderator hangs up',
      manual: 'Manual',
      automatic: 'Automatic'
    },
    anytime: 'Anytime',
    immediate: 'Immediately',
    onetime: 'One-time',
    repeated: 'Repeated',
    nameOfRoom: 'Name of Room',

    noParticipants: 'No Participants',
    noParticipantsAvailable: 'No participants available',
    noParticipantsFiltered: 'No participant matches the search criteria.',
    loadingParticipants: 'Loading participants',
    repeatConference: 'Repeat Conference',
    recordingManuallyDeleted: 'Recording manually deleted',
    noRecording: 'No recording available',
    deleteSeries: 'Delete Conference Series',
    topicPlaceholder: 'Enter topic... (e.g., Marketing-Jour Fix)',
    topicPlaceholder2: 'Enter a topic...',
    languageDescription: 'Language of the email and announcements',
    playbackOrLive: 'Are you planning a live conference or playback?',

    selectPlayback: 'Select Playback',
    selectedPlayback: 'Selected Playback',
    noPlaybackSelected: 'No playback selected',
    immediateDescription: 'The conference starts immediately after the booking process',
    startDate: 'Start Date',
    time: 'Time',
    oneDate: '1 Date',
    dates: 'Dates',
    maxHundredDates: 'Maximum 100 dates',
    startDateAfterEnd: 'Start date comes after the end date',
    daily: 'Daily',
    weekly: 'Weekly',
    everyTwoWeeks: 'Every two weeks',
    everyThreeWeeks: 'Every three weeks',
    everyVierWeeks: 'Every four weeks',
    everyNWeeks: 'Every {n} weeks',
    monthly: 'Monthly',
    everyFirst: 'Every 1st',
    everySecond: 'Every 2nd',
    everyThird: 'Every 3rd',
    everyFourth: 'Every 4th',
    everyLast: 'Every last',
    dialInDescription: 'To dial into the conference, participants can call one of the numbers below.',
    welcomingAndSecurity: 'Welcoming and Security',
    welcomingDescription: 'After dialing in, participants are automatically greeted and prompted to enter a 6-digit access code.',
    pinForMod: 'Access code for Moderators',
    pinForPart: 'Access code for Participants',
    enterPin: 'Enter 6-digit PIN',
    pinErrors: {
      sixDigits: 'Please enter six digits',
      different: 'Pins must be different',
      exists: 'Access code is not possible at this time',
      modCodeExists: 'Moderator access code is not possible at this time',
      partCodeExists: 'Participant access code is not possible at this time'
    },
    continueEditing: 'Continue editing conference',
    pleaseFixError: 'Please fix the errors',
    pleaseClickOutside: 'Please click outside the participant table to validate all fields',
    pleaseSelectPlayback: 'Please select a playback',
    startMinimumTenSeconds: 'The conference start time must be at least 10 seconds in the future.',
    participantsMinimum: 'Please enter at least one participant',
    moderatorMinimum: 'Please enter at least one moderator',
    chooseFromAdressbook: 'Choose from Address Book',
    namePlaceholder: 'Enter name',
    numberPlaceholder: 'e.g., +49 123 4567890',
    inputEmail: 'Enter email',
    inputCompany: 'Enter company',
    participantDeleted: 'Participant successfully deleted!',
    countParticipantsDeleted: '{count} participant successfully deleted! | {count} participants successfully deleted!',
    participantAdded: 'Participant successfully added!',
    addRoom: 'Create Conference Room',
    bookRoom: 'Book Conference Room',
    room: 'Conference Room',
    roomDescription: 'After setup, the conference room is immediately available for use. You can use it anytime.',
    backTo: 'Back to {name}',
    continueTo: 'Continue to {name}',
    noChangePossible: 'Editing or deletion is not possible immediately before the conference start.',
    selectDialInOrCallParticipants: 'Please enable at least "Dial-In" or "Call Participants".',
    editOrDeleteIstNotPossibleBeforeStart: 'Editing or deletion is not possible immediately before the conference start.',
    numberConfirmedTotalParticipants: 'Number confirmed / total registered participants',
    showMore: 'show more',
    collaboration: 'Collaboration',
    audioStreaming: 'Audio streaming',
    unknown: 'unknown',
    busy: 'busy',
    notReached: 'not reached',
    ended: 'ended',
    extraFieldAFI: 'Extra field AF {i}',
    checkboxAFI: 'Checkbox AF {i}',
    extraFieldUFI: 'Extra field UF {i}',
    checkboxUFI: 'Checkbox UF {i}',
    customFieldAFI: 'custom field AF {i}',
    customFieldUFI: 'custom field UF {i}',
    registrationTimeAF: 'Registration time AF',
    registrationTimeUF: 'Registration time UF',
    certificateSentAF: 'Certificate sent (AF)',
    certificateSentUF: 'Certificate sent (UF)',
    mailDuplicatesDetected: 'An email address or phone number is in the participant list twice',
    onlyParticipantsWithNumberAreCalled: 'Please note that only those participants who provided their phone number during registration will be called. All other participants must dial in themselves.',
    dialoutButNoPhoneRequired: 'A registration form that does not have the telephone number as a mandatory field cannot be assigned to a dial-out conference.',
    dialInDeactivatedWarning: 'This conference has a registration form that does not require you to provide a telephone number. Therefore, the dial-in option must remain activated.'
  },
  modals: {
    deleteBooking: {
      title: 'Delete Conference or Conference Series',
      description:
        'This conference is part of a series. Do you want to delete the entire conference series or just this conference?',
      deleteSeries: 'Delete Series',
      deleteConferenceOnly: 'Delete Only Conference'
    },
    deleteScheduled: {
      title: 'Delete Scheduled Conference',
      description: 'Do you really want to delete the scheduled conference?'
    },
    mailToParticipants: {
      title: 'Send Email to Participants',
      description:
        'Send an email to {count} participants of the conference <b>{title}</b>.'
    },
    deleteRecording: {
      title: 'Delete File',
      description: 'Do you want to delete the recording?',
      confirm: 'Delete Recording',
      confirmed: 'Recording deleted successfully!'
    },
    deleteConference: {
      title: 'Delete Conference',
      description: 'Do you really want to delete the conference?',
      confirmed: 'Conference deleted successfully!'
    },
    deleteSeries: {
      title: 'Delete Conference Series',
      description: 'Do you really want to delete the conference series?',
      confirmed: 'Conference series deleted successfully!'
    },
    seriesChangesForms:
      'Please note that changing a series date requires reassignment of registration and survey forms.',
    wizardError: {
      description:
        'This wizard step contains an error or unsaved changes. Do you want to discard your changes and go to the next step or stay here?',
      title: 'Unsaved or Faulty Changes',
      ok: 'Discard and Continue',
      cancel: 'Stay Here'
    },
    deleteGroup: {
      title: 'Delete Group',
      description:
        'Do you want to delete the group, the group with contacts, or only the included contacts?',
      withContacts: 'Delete Group with Contacts',
      onlyContacts: 'Delete Only Contacts',
      onlyGroup: 'Delete Only Group',
      otherOptions: 'Other Options'
    },
    emptyAdressbook: {
      title: 'Empty Address Book',
      description:
        'Do you want to delete all contacts? Or all contacts not assigned to any group?',
      contactsAndGroups: 'Delete Contacts and Groups',
      allContactsAndGroups: 'Delete All Contacts and Groups',
      otherOptions: 'Other Options',
      withoutGroup: 'Delete all contacts not assigned to any group'
    },
    deleteSelectedContacts: {
      title: 'Delete Selected Contacts',
      description: 'Do you want to delete the selected contacts?',
      ok: 'Delete Selected Contacts'
    },
    deleteContact: {
      title: 'Delete Contact',
      description: 'Do you want to delete the contact "{name}"?',
      ok: 'Delete Contact'
    },
    operatorConference: {
      title: 'Assign Conference',
      description:
        'By assigning a conference, the speakers, date, and settings are automatically adopted.'
    },
    operatorRegForm: {
      title: 'Assign Registration Form',
      description:
        'By assigning a registration form, the date and other settings are automatically adopted.',
      selectDate: 'Select a date.',
      selectDateShort: 'Select Date...'
    },
    operatorSurveyForm: {
      title: 'Assign Survey Form',
      description:
        'By assigning a survey form, the operator can better assess the scope of the conference.',
      selectDate: 'Select a date.',
      selectDateShort: 'Select Date...'
    },
    deleteSeriesOrConference: {
      title: 'Delete Conference or Conference Series',
      description:
        'This conference is part of a series. Do you want to delete the entire conference series or just this conference?',
      deleteSeries: 'Delete Series',
      onlyConference: 'Delete Only Conference'
    },
    brandingPrivacyModal: {
      title: 'Privacy Statement',
      ok: 'Close',
      description: 'Here you can change the privacy statement',
      privacyPreview: 'Privacy Statement Preview',
      privacyText:
        'You can find information on the processing of your personal data in the <span class="text-primary">Privacy Statement</span>.',
      privacyHint:
        'Privacy statement link is set in the form(s)'
    },
    brandingFooterModal: {
      title: 'Footer',
      ok: 'Close',
      footerActive: 'Footer Activated',
      description: 'Enter a new footer here',
      footerPreview: 'Footer Preview',
      footerText:
        'The data you provide with the registration will be processed by {company} and the service provider CSN. Communication Service Network GmbH for the purpose of conducting the webinar.',
      noFooter: 'No Footer'
    },
    regInfoModal: {
      title: 'Share Registration Form',
      ok: 'Close',
      description1:
        'You can copy the link to the form and send it to your participants by email. Alternatively, you can embed it on your website or promote it on social networks.',
      weblink: 'Your weblink to the registration form is',
      idParam: 'Sales-Team-UK',
      weblinkExample:
        'Example of a weblink with prefilling of a partner ID',
      note:
        'For internet-savvy users, there is the option to prefill the standard and additional fields of the registration form via URL parameters. This way, you can provide your sales partners with individual registration links in which the partner ID is encoded. If a participant registers through such a link, they can later be assigned to a partner.',
      footer:
        'URL parameters can be used for standard fields (firstname, lastname, email, phone, company, comment, id or partnerId, addressStreet, addressCity, vvId, salutation) and also for additional fields.'
    },
    surveyInfoModal: {
      title: 'Share Survey Form',
      ok: 'Close',
      description1:
        'You can copy the link to the form and send it to your participants by email. Alternatively, you can embed it on your website or promote it on social networks.',
      weblink: 'Your weblink to the survey form is',
      weblinkExample:
        'Example of a weblink with prefilling of a partner ID',
      note:
        'For internet-savvy users, there is the option to prefill the standard and additional fields of the registration form via URL parameters. This way, you can provide your sales partners with individual registration links in which the partner ID is encoded. If a participant registers through such a link, they can later be assigned to a partner.',
      footer:
        'URL parameters can be used for standard fields (firstname, lastname, email, phone, company, comment, id or partnerId, addressStreet, addressCity, vvId, salutation).'
    },
    deleteFormModal: {
      title: 'Delete Form',
      description: 'Do you really want to delete the form?',
      ok: 'Delete Form',
      deleted: 'Form deleted successfully!'
    },
    deleteParticipantsModal: {
      title: 'Delete Participants',
      description: 'Do you really want to delete all participants?',
      ok: 'Delete All Participants',
      deleted: 'Form participants deleted successfully!'
    },
    deleteOldParticipantsModal: {
      title: 'Delete All Old Participants',
      ok: 'Delete All Old Participants',
      description: 'Do you really want to delete all old participants?',
      deleted: 'All old participants deleted successfully.'
    },
    deleteOldRegDatesAndParticipantsModal: {
      title: 'Delete All Old Dates & Participants',
      ok: 'Delete All Old Dates & Participants',
      description:
        'Do you really want to delete all old dates & participants?',
      deleted: 'All old dates & participants deleted successfully.'
    },
    deleteAllOldRegModal: {
      title: 'Delete All Old Forms',
      ok: 'Delete All Old Forms Completely',
      description:
        'Do you really want to completely delete all old forms? It is then no longer possible to evaluate the associated event.',
      deleted: 'All old forms completely deleted.'
    },
    deleteDateParticipants: {
      title: 'Delete All Participants',
      ok: 'Delete Participants',
      description:
        'Do you really want to delete all registered participants of the selected date?',
      deleted: 'Participants have been deleted!'
    },
    deleteAllDatesParticipants: {
      title: 'Delete All Participants',
      ok: 'Delete Participants',
      description:
        'Do you really want to delete all registered participants of the form?',
      deleted: 'All participants have been deleted!'
    },
    deleteDateParticipantsLong: {
      title: 'Delete Participants',
      ok: 'Delete {count} Participants',
      description:
        'Do you want to delete the participants of the date \'{title}\'? For a historical date, this will delete extended conference details.',
      deleted: 'Participants deleted successfully'
    },
    sendFormMail: {
      title: 'Send Email to Participants',
      regDescription:
        'send an email to <b>{count}</b> participants of the date <b>{date}</b>',
      replyEmail: 'Reply Email'
    },
    deleteParticipant: {
      title: 'Delete Participant',
      description: 'Do you want to delete the participant {name}?',
      ok: 'Delete Participant',
      deleted: 'Participant deleted successfully'
    },
    deleteParticipants: {
      title: 'Delete Participants',
      description: 'Do you want to delete {count} participants?',
      ok: 'Delete Participants',
      deleted: 'Participants deleted successfully'
    },
    deletePresentation: {
      title: 'Delete Presentation',
      description: 'Do you want to delete the presentation {name}?',
      ok: 'Delete Presentation',
      deleted: 'Presentation deleted successfully'
    },
    deletePresentations: {
      title: 'Delete Presentations',
      description: 'Do you want to delete {count} presentations?',
      ok: 'Delete Presentations',
      deleted: 'Presentations deleted successfully'
    },
    deleteMaterial: {
      title: 'Delete Material',
      description: 'Do you want to delete the material {name}?',
      ok: 'Delete Material',
      deleted: 'Material deleted successfully'
    },
    deleteMaterials: {
      title: 'Delete Materials',
      description: 'Do you want to delete {count} materials?',
      ok: 'Delete Materials',
      deleted: 'Materials deleted successfully'
    },
    deleteDownloads: {
      title: 'Delete Downloads',
      description: 'Do you really want to delete {count} download? | Do you really want to delete {count} downloads?',
      ok: 'Delete Downloads',
      deleted: 'Downloads deleted successfully'
    },
    deleteDownload: {
      title: 'Delete Download',
      descriptionDE: 'Möchten Sie den Download "{name}" wirklich löschen?',
      description: 'Do you want to delete the download "{name}"?',
      ok: 'Delete Download',
      deleted: 'Download deleted successfully'
    },
    deleteSurvey: {
      title: 'Delete Form',
      descWithParticipants:
        'The selected form has registered participants. Do you still want to delete it?',
      descNoParticipants: 'Do you really want to delete this form?',
      ok: 'Delete Form',
      deleted: 'Form deleted successfully!'
    },
    deleteSurveyResponses: {
      title: 'Delete Participants',
      description:
        'Do you want to delete all participants of the survey form?',
      ok: 'Delete Participants',
      deleted: 'Participants deleted successfully!',
      noResponses: 'The survey form has no participants.'
    },
    certificateSetup: {
      title: 'Certificate Setup'
    },
    importParticipants: {
      title: 'Import Participants',
      requiredFields:
        'Please assign the mandatory fields Last Name, Phone Number, and Email.',
      encodingXlsxCsv: 'Encoding of the XLSX/CSV file:',
      pullCsvHere: 'Drag XLSX/CSV file here',
      validDataset: 'valid datasets',
      validDatasetCap: 'Valid datasets',
      invalidDatasets: 'invalid datasets',
      deletedDatasets: 'deleted datasets',
      editInvalidDatasets: 'Edit invalid datasets:',
      editDatasetDescription:
        'Correct or review your datasets and confirm afterward.',
      participantsExistsDescription:
        'These participants already exist in the form as participants or in the valid or invalid datasets with the same email address or phone number.'
    },
    gutBeratenSetup: {
      title: '"Gut beraten"- Setup',
      csvRequired:
        'For reporting participants who have participated in an "Gut beraten" educational measure, a CSV file in a special format is required.',
      minimumConnectionTime: 'Minimum Connection Time',
      educationBonus: 'Educational Time Credit',
      learningTypes: {
        face2face: 'Face-to-face event',
        individual: 'Individual training',
        blended: 'Blended Learning',
        controlled: 'Controlled e-learning',
        selfControlled: 'Self-controlled e-learning',
        workplace: 'Workplace learning'
      },
      learningContents: {
        privatePreventionLifePensionInsurance:
          'Private prevention life/pension insurance',
        privatePreventionHealthCareInsurance:
          'Private prevention health care insurance',
        privatePropertyDamageInsurance:
          'Private property damage insurance',
        corporateCustomerPrevention:
          'Corporate customer prevention (company pension scheme/personal insurance)',
        corporateCustomerPropertyDamageInsurance:
          'Corporate customer property damage insurance',
        crossDivisional: 'Cross-divisional',
        consultingSkills: 'Consulting skills'
      }
    },
    placeholder: {
      placeholderFor: 'Placeholder for {title}',
      regTitle: 'Title of the registration form',
      regSubtitle: 'Subtitle of the registration form',
      regDescription: 'Description text of the registration form',
      salutation: 'Dear Mr., Dear Ms., Dear',
      numberWithCountryCode: 'Phone number with country code',
      email: 'Email address',
      regDateTime: 'Date of registration (DD. Month YYYY, HH:MM am/pm)',
      regPin: 'PIN of the event',
      regDialInNumber: 'Dial-in numbers to the conference (DE/EN)',
      regDialInInternational: 'Dial-in numbers to the conference (International)',
      owner: 'Account owner (see profile)',
      regDoiLink: 'Link to confirm Double-Opt-In',
      regPresentationLink: 'Link to the presentation ({url}). Name & PIN are pre-filled',
      regLoginButton: 'Personalized login button to join the presentation ({url}). Name, PIN and all other inputs are passed in the background.',
      surveyTitle: 'Title of the survey form',
      surveySubtitle: 'Subtitle of the survey form',
      surveyDescription: 'Description text of the survey form',
      surveyDateTime: 'Date of participation (DD. Month YYYY, HH:MM am/pm)',
      surveyDate: 'Date of participation (DD.MM.YYYY)',
      surveyPassed: 'This text appears on passing the exam.',
      surveyFailed: 'This text appears on failing the exam.',
      surveyReachedPoints: 'Reached points',
      surveyTotalPoints: 'Total points',
      surveyPercent: 'Achieved percentage of points (e.g., 50%)',
      connectionTime: 'Connection time, rounded to full minutes'
    },
    deleteField: {
      title: 'Delete input field',
      description: 'Are you sure you want to delete this input field?',
      ok: 'Delete input field'
    },
    deleteAccount: {
      title: 'Delete account',
      description: 'Are you sure you want to delete user {name}?',
      ok: 'Delete account'
    },
    passwordChange: {
      newPasswordTitle: 'Set New Password',
      passwordConfirmDoesNotMatch: 'The new password and the confirmation do not match',
      completeForm: 'Please complete the form',
      newIsSameError: 'Old and new passwords must not be the same.',
      newPasswordSecurity: 'The new password must contain at least 8 characters, uppercase and lowercase letters, at least one digit, and one special character.',
      oldPasswordNotCorrect: 'The old password is incorrect',
      passwordWasUsedInPast: 'The password was used in the past.',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      repeatNewPassword: 'Repeat New Password',
      savePassword: 'Save New Password'
    },
    deleteDate: {
      description: 'Do you really want to delete the appointment? One participant is registered. | Do you really want to delete the appointment? {count} participants are registered.',
      title: 'Delete Appointment',
      ok: 'Delete Appointment'
    },
    title: 'Confirm Deletion',
    description: 'Do you really want to delete the selected brandings?',
    descriptionSingle: 'Do you really want to delete the Waitingroom-Branding "{name}"?',
    ok: 'Delete Brandings',
    okSingle: 'Delete Branding'
  },
  forms: {
    components: {
      doi: {
        name: 'Double-Opt-In',
        legalHint: 'If a form is linked to an invitation for a promotional event or conference registration with a newsletter sign-up, the Double-Opt-In procedure must be used according to the General Data Protection Regulation (GDPR) and the Unfair Competition Act (UWG). In this case, the interested party receives an email with a confirmation link, which they must actively confirm to complete their registration.',
        conferenceOption: 'Double-Opt-In for registration for a promotional event',
        conferenceHint: 'Active confirmation of registration is required for participation in the event.',
        newsletterOption: 'Double-Opt-In for consent regarding promotional data processing (e.g., newsletter)',
        newsletterHint: 'In this case, active confirmation does not relate to participation in the event.',
        newsletterCheckboxLabel: 'Checkbox Text*',
        newsletterCheckboxDefault: 'I hereby consent to the use of my email address for sending the newsletter. This consent can be revoked at any time.',
        disabled: 'Disable Double-Opt-In',
        reminder: 'Send reminder email in case of missing confirmation one day before the event starts',
        mailTextInvalid: 'The mail text must not be empty and must contain the placeholder for the confirmation link.',
        hasParticipants: 'Since participants are already registered, you cannot change the Double-Opt-In setting anymore.'
      },
      email: {
        mailAfterRegistration: 'Email after registration',
        pinOfEvent: 'PIN of the event',
        showParticipantInput: 'Show participant input',
        responseMail: 'Response email address*',
        bcc: 'BCC recipient',
        subject: 'Subject',
        text: 'Email text',
        reminderMail: 'Reminder email 1 hour and 1 day before the event starts',
        selectMailTemplate: 'Select an email template',
        answersAndPersonalData: 'Surveys and personal data',
        answers: 'Surveys only',
        personalData: 'Personal data only',
        none: 'Do not show',
        dialOutWarning: 'You have selected \'Dial-Out Conference\' as the email template. For this conference type, it is mandatory to query the phone number as a required field so that participants can be called.',
        dialOutInWarning: 'You have selected \'Dial-Out Conference with Dial-In Option\' as the email template. However, you are not querying the phone number as a required field. Please note that only participants who provided their phone number during registration will be called. All other participants will need to dial in themselves.'
      },
      general: {
        defaultName: 'Name / Identifier of the form',
        title: 'Event title',
        subtitle: 'Event subtitle'
      },
      participation: {
        getParticipationData: 'Collect participant data',
        predefinedInputFields: 'Predefined input fields',
        customInputField: 'Custom input fields',
        customField: 'Additional custom field',
        requiredDescription: 'If survey results should be displayed in the extended conference details, then "Name, Email, Phone" or "Gut beraten ID" must be consistently queried as mandatory fields in the registration and survey form. In the first case, matching is done via the email address.',
        customCheckbox: 'Custom checkboxes',
        anotherCheckBox: 'add checkbox',
        commentField: 'Comment field',
        nameMail: 'Name, Email',
        defaultInputFields: 'Default input fields',
        dialOutRequired: 'required for dial-in conferences'
      },
      privacy: {
        dataProtection: 'Privacy policy:',
        byBranding: '(adopted by branding)',
        weblink: 'Weblink to the privacy policy*',
        example: 'Example: https://www.example.com/privacy',
        contentResponsibility: 'Content responsible* (Name, Company)',
        dsgvoDescription: 'According to Article 13 GDPR, as the data controller, you have an obligation to provide information regarding the collection of personal data. Please enter the weblink to your privacy policy below.',
        impressumDescription: 'in accordance with § 18 (2) MStV. The representation is in the imprint of the form.',
        privacyImpressum: 'Privacy, Imprint'
      },
      endUrl: {
        title: 'Redirect after the Event',
        description: 'At the end of an online event, you can automatically redirect your participants to a website. This can be your homepage, a product page, or any other destination. If you leave the field empty, no redirection will occur.',
        endUrlLabel: 'Redirect URL',
        placeholder: 'Example: https://www.csn.gmbh'
      },
      questions: {
        noQuestions: 'No questions.',
        deleteQuestion: 'Delete question',
        explanation: 'Explanation of the question',
        selectTypeOfQuestion: 'Select the type of question',
        questionTypeCannotBeChanged: 'Since participants are already registered, the question type cannot be changed anymore.',
        bonusPoints: 'Bonus or penalty points per correct or incorrect answer',
        evaluationInfo: 'Information for evaluation',
        noEvaluation: 'No evaluation desired',
        answers: 'Answers',
        answer: 'Answer',
        correct: 'Correct',
        atLeastTwoAnswers: 'Please add at least two answers.',
        atLeastOneCorrect: 'Since the question is scored, specify at least one answer as correct.',
        anotherAnswer: 'add Answer',
        oneAnswer: 'Since the question is scored, specify one answer as correct.',
        pleaseAddSubpoint: 'Please add a subpoint',
        newSubpoint: 'New subpoint',
        noNewSubpoints: 'Since participants are already registered, no new subpoints can be added.',
        noNewQuestions: 'Since participants are already registered, no new questions can be added.',
        addQuestion: 'Add question',
        multipleChoice: 'Multiple Choice',
        singleChoice: 'Single Choice',
        textWithSubpoints: 'Text question with subpoints',
        textBox: 'Text question with textbox',
        titleQuestionN: 'Title Question {number}'
      },
      speakers: {
        speakers: 'Speakers',
        speakerName: 'Speaker name'
      }
    },
    mailTemplate: 'Email template',
    mailText: 'Email text',
    replyToEmail: 'Reply-to email address',
    subject: 'Subject',
    requiredAndEmail: 'Mandatory field must be filled and be a valid email address.',
    text: 'Text',
    placeholderInfo: 'Info about placeholders',
    invalidMail: 'Please provide a valid email address.',
    sendTestMail: 'Send test email',
    toAllParticipants: 'To all participants',
    toSelectedParticipants: 'To participants who have not received one yet',
    testMailSuccess: 'Test email successfully sent!',
    sendMailSuccess: 'Email successfully sent!',
    certificateSetup: 'Certificate setup',
    certificateToSelected: 'Send certificate to participants',
    certificateToAll: 'Send certificates to all participants',
    yourCertificate: 'Your participation certificate',
    participationCertificate: 'Participation certificate',
    certificateAtThe: 'Participation certificate at the',
    surveyModalDescription: 'Do you want to conduct a survey or multiple-choice test during the conference? Then assign the corresponding survey form here. The extended conference details will be supplemented with the survey results if a "Gut beraten ID" or email address is queried in the registration and survey form.',
    regModalDescription: 'Do you use a registration form for participant registration? Then assign the registration form date to the conference. This will display the participants as a group in the control and can be easily switched on.',
    registrationForm: 'Registration form',
    surveyForm: 'Survey form',
    date: 'Date',
    dates: 'Dates',
    selectDate: 'Select date',
    missingDate: 'Please select a date',
    formSuccess: 'Form successfully modified',
    noTitle: 'No title',
    dateTitleNumber: 'Title Date {number}',
    assignedElsewhere: 'assigned elsewhere',
    noForm: 'no form',
    newRegForm: 'New registration form',
    newSurveyForm: 'New survey form',
    brandings: 'Branding | Brandings',
    newBranding: 'Create branding',
    noBranding: 'no branding',
    fileNotUploaded: 'A file could not be uploaded. Please try again.',
    topic: 'Topic',
    regCertificate: '<p>{ANREDE} {VORNAME} {NACHNAME},</p><p><br></p><p>enclosed you will find your certificate of participation in our training on {DATUM}.</p><p><br></p><p>Best regards,</p><p><br></p><p>{ACCOUNTINHABER}</p>',
    createNewRegForm: 'Create a new registration form',
    createNewSurveyForm: 'Create a new survey form',
    customUrlParams: 'Benutzerdefinierte URL-Parameter',
    newForm: 'New form',
    activeForms: 'Active form | Active forms',
    currentForms: 'Current form | Current forms',
    oldForms: 'Old form | Old forms',
    outdatedForms: 'Outdated form | Outdated forms',
    oldFormsDescription: 'Forms that have not been changed or used for 6 months',
    oldRegsDescription: 'Forms that only contain expired appointments',
    activeDates: 'Active date | Active dates',
    deleteOldParticipants: 'Delete all old participants',
    deleteOldParticipantsDates: 'Delete all old dates and participants',
    deleteAllForms: 'Completely delete all old forms',
    deleteAfterMonths: 'Deletion after {months} months',
    totalParticipantCount: 'Total participant count',
    visitForm: 'Visit form',
    lockForm: 'Lock form',
    unlockForm: 'Unlock form',
    duplicateForm: 'Duplicate form',
    editForm: 'Edit form',
    blacklistOn: 'Blacklist: On',
    blacklistOff: 'Blacklist: Off',
    turnOffBlacklist: 'Turn off blacklist',
    turnOnBlacklist: 'Turn on blacklist',
    deleteParticipants: 'Delete participants',
    deleteForm: 'Delete form',
    moreDates: 'More dates',
    noDates: 'No dates',
    datesList: 'Dates list',
    duplicated: 'Form successfully duplicated!',
    blacklistEnabled: 'Blacklist enabled',
    blacklistDisabled: 'Blacklist disabled',
    editRegForm: 'Edit registration form',
    saveChanges: 'Save changes',
    savingChanges: 'Changes are being saved',
    formHasErrors: 'Please correct the highlighted errors or complete the page.',
    noCertificate: 'You must upload a template for participant certificates.',
    formSaved: 'Form successfully edited',
    formCreated: 'Form successfully created',
    datesCount: 'Number of dates',
    deleteAllParticipants: 'Delete all participants',
    certificatesGenerating: 'Certificates are being generated...',
    certificatesDownloading: 'Certificates are being downloaded...',
    generatedCertificates: 'Certificates generated',
    certDownloadStarted: 'Certificate dispatch started...',
    certificatesSent: 'Certificates sent successfully!',
    sendCertificates: 'Send certificates',
    sentCertificates: 'Cert. sent',
    downloadCertificates: 'Download certificates',
    downloadSimpleParticipants: 'Download (Name, Number)',
    downloadFullParticipants: 'Download (full)',
    importParticipants: 'Import participants',
    toAdressbook: 'Transfer to address book',
    emailToParticipants: 'Email to participants',
    noRegForm: 'No registration form available',
    noSubtitle: 'No subtitle',
    simpleRegCsvTitle: 'Participants_{date} (Name-Number)',
    fullRegCsvTitle: 'Participants_{date} (full)',
    doiVerified: 'Double-Opt-In confirmed',
    doiNotVerified: 'Double-Opt-In not confirmed',
    noParticipants: 'No participants available.',
    noParticipantsRegistered: 'No participants registered',
    nParticipantsRegistered: '{number} participants registered',
    usersLoading: 'Users are loading...',
    birthdayOrId: 'Birthday (or internal personal identifier)',
    educationName: 'Name of the educational measure',
    learningType: 'Learning type',
    learningContent: 'Learning content',
    educationDuration: 'Educational time in minutes',
    internalBookingId: 'Internal booking ID',
    contactTitle: 'Contact person title',
    contactFirstname: 'Contact person first name',
    contactLastname: 'Contact person last name',
    contactPhone: 'Contact person phone',
    contactEmail: 'Contact person email',
    gutBeratenChanged: 'Gut beraten successfully updated!',
    editParticipant: 'Edit participant',
    minDurationNotReached: 'Participant has not reached the required participation duration ({duration} min)',
    minDurationNotReachedByAnyone: 'No participant has reached the required participation duration ({duration} min)',
    doiNotConfirmed: 'Participant has not confirmed Double-Opt-In',
    certificateGenerating: 'Certificate is being generated',
    sendCertificate: 'Send certificate',
    certificateSentShort: 'Certificate sent',
    certificateSent: 'Certificate has been sent',
    downloadCertificate: 'Download certificate',
    selectedParticipants: 'Selected participants',
    registration: 'Registration',
    doi: 'DOI',
    userEdited: 'Participant successfully edited',
    emailSent: 'Email successfully sent!',
    testEmailSent: 'Test email successfully sent!',
    participantsAddedToAdressbook: 'Participants successfully transferred to address book.',
    createRegForm: 'Create registration form',
    noCertificateError: 'You must upload a template for participant certificates.',
    noSurveyForms: 'No survey forms available',
    lastParticipation: 'Last participation',
    editSurveyForm: 'Edit survey form',
    editLimited: '(Limited because participants are already registered)',
    createSurveyForm: 'Create survey form',
    surveyResults: 'Survey results',
    statistic: 'Statistics',
    generateStatistic: 'Generate statistics',
    downloadEvaluation: 'Download evaluation',
    evaluation: 'Evaluation',
    noParticipantsPassed: 'No participant has passed',
    noSurvey: 'Survey not available',
    notEvaluated: 'Not evaluated',
    notEvaluatedShort: 'n. e.',
    noEmail: 'Participant has not provided an email address',
    notEnoughPoints: 'Participant has not reached the required score ({points}%)',
    notEnoughDuration: 'Participant has not reached the required participation duration ({minutes} min)',
    downloadSingleEvaluation: 'Download individual evaluation',
    didNotPass: 'Participant did not pass',
    evaluationCertificate: 'Evaluation / Participant certificate',
    participantsCertificate: 'Participant certificate',
    certificateSubtitle: 'Not required if a survey form with a certificate is used',
    schoolingLimitFrom: 'A test is considered passed from',
    pointsFrom: 'Multiple-choice score of',
    pointsAmount: 'Equals {percent} of {total} points',
    durationOf: 'Participation duration of',
    certificateForParticipants: 'Certificate for participants',
    certificate: 'Certificate',
    certificates: 'Certificates',
    certificateInfo: 'Participant certificates can be individually created in PowerPoint. See our template or explanation of placeholders for details.',
    pptTemplate: 'PowerPoint template',
    placeholder: 'Placeholder',
    preview: 'Preview',
    certificateFootnotes: 'The email dispatch of certificates can be released after the end of the event via the participant list.',
    certificatePreview: 'Certificate preview',
    variableChanged: 'Variable changed successfully!',
    startTimeChangedDeleteParts: 'The start time has been changed. Should the registered participants be deleted?',
    extras: 'Extras',
    hide: 'Hide',
    show: 'Show',
    lastRegistration: 'Last registration',
    notActivated: 'Not activated',
    countdownFrom: 'Countdown from',
    dateDescription: 'Date description',
    allowRegistrationOnMultipleDates: 'Allow registration on multiple dates',
    addDate: 'Add date',
    nMinutesBeforeStart: '{minutes} minutes before start',
    activationPeriod: 'Activation period',
    urlCopied: 'URL copied',
    gutBeratenID: 'Gut beraten ID (VV-ID)',
    labelExtraField: 'Label additional field',
    extraField: 'additional field',
    extraFieldN: 'additional field {number}',
    labelCheckbox: 'Label checkbox',
    labelSubpoint: 'Label subpoint',
    vvIdFormat: 'Format: YYYYMMDD-XXXXXX-XX',
    vvIdInvalid: 'VV-ID must match the specified format',
    fixErrorInForm: 'Please fix the errors in the form',
    checkbox: 'Checkbox',
    checkboxN: 'Checkbox {number}',
    defaultSurveyText: '<p>{ANREDE} {VORNAME} {NACHNAME},</p><p><br></p><p>attached you will find your certificate for participating in our training on {DATUM}.</p><p><br></p><p>Best regards,</p><p><br></p><p>{ACCOUNTINHABER}</p>',
    continueTo: 'Continue to {name}',
    registrationTime: 'Registration time',
    partnerID: 'Partner ID',
    selectDialInOrDialOut: 'Please enable at least "Dial-In" or "Dial-Out".',
    gutBeratenExists: '"gut beraten" exists',
    outlookCalendar: 'Outlook calendar entry',
    insertReplyMail: 'Please enter your reply email address',
    participantDetails: 'Participant details',
    reachedPoints: 'Reached points',
    questionsCorrect: 'Questions answered correctly',
    passed: 'Passed',
    participation: 'Participation',
    fromNPercent: 'from {percent}%',
    questionNWithBox: 'Question {questionNumber} (with answer box)',
    questionMultipleTextAndAnswer: 'Question {questionNumber} (with subpoints)',
    questionSingleChoice: 'Question {questionNumber} (single choice)',
    questionMultipleChoice: 'Question {questionNumber} (multiple choice)',
    individualEvaluationFilename: 'Individual-evaluation-of-the-survey',
    excelLegend: {
      evaluationAndLegend: 'Evaluation and Legend',
      singleChoiceTitle: 'Information on the Evaluation of Single Choice Questions',
      singleChoiceDescription: 'For Single-Choice questions, the evaluation is straightforward. It operates on a simple bonus system: only those who have selected the correct answer receive the full score (one point or more, depending on weighting) for the question. All candidates with incorrect or missing answers receive no points.',
      multipleChoiceTitle: 'Information on the Evaluation of Multiple Choice Questions',
      multipleChoiceDescription1: 'For a Multiple-Choice question, a bonus system is inadequate. Therefore, a penalty system is used here. In the penalty system, there is also an evaluation for incorrect answers, but in the form of negative points or deductions. Bonus points for correct answers (selecting correct answer options, not selecting incorrect ones) and penalty points for incorrect ones (not selecting correct answer options, selecting incorrect ones). Completing a task with negative points is excluded by setting zero as the lowest possible score for a task (so-called "soft penalty system").',
      multipleChoiceDescription2: 'Bonus and penalty points are of equal value. The equal valuation of incorrect and correct answers is the simplest and most common method.',
      multipleChoiceDescription3: 'The evaluation process is based on a guide from Hochschule Ludwigshafen am Rhein, which is based on recommendations from the Technical University of Munich and the University of Bern.',
      example: 'Example',
      multipleChoiceDescriptionExample: 'Evaluation of a Multiple-Choice question with two correct answers out of 5 possible alternatives',
      marked: 'Marked',
      notMarked: 'Not marked',
      correct: 'Correct',
      incorrect: 'Incorrect',
      else_: 'Else',
      legend: 'Legend',
      abbSingleChoice: 'SC = Single Choice Question',
      abbMultipleChoice: 'MC = Multiple Choice Question',
      descriptionHead: 'In the header of an exam question, the type (SC/MC) is mentioned.',
      scFullPoints: 'For SC questions, you will also find the full score for a correct answer.',
      mcTwoValues: 'For MC questions, 2 values are noted. On the left are the bonus or penalty points per option',
      rightMaxPoints: 'and on the right is the maximum score achievable for the question. Example: 1/4',
      evaluationOnEnd: 'The overall evaluation of an exam can be found on the right at the end of the table.',
      tableHeadAllPoints: 'In the table header, the achievable total score is displayed, and the value you selected',
      valuePassedFrom: 'from which a test is considered passed',
      resultPerParticipant: 'In addition, the test result is calculated per participant'
    },
    reachedPointsResult: 'Points achieved: {reached} out of {total}',
    points: '{reached} out of {achieved}',
    evaluationFilename: 'Evaluation-of-the-survey',
    surveyDetails: 'Details of the survey',
    passedFrom: 'Passed from',
    timestamp: 'time stamp',
    addressDetails: 'Address details',
    certificateTemplateUrl: 'https://static.csn{env}.de/download/Powerpoint-Certificate-Template.pptx',
    informationOnEvaluationUrl: 'https://static.csn{env}.de/download/Information-on-the-evaluation-system.pdf',
    urlExists: 'The web directory is already taken',
    invalidUrl: 'Please enter a valid URL. Example: https://www.csn.gmbh',
    deadline: 'Deadline',
    surveyStatistics: 'Survey_statistics',
    hideExpiredDates: 'Hide expired dates',
    customFields: {
      customFields: 'Custom Input Fields',
      newField: 'New Input Field',
      noFields: 'No Custom Input Fields',
      saveField: 'Save Input Field',
      name: 'Label',
      notice: 'Instruction (appears on mouseover)',
      noticeShort: 'Instruction (Tooltip)',
      defineField: 'Define Input Field',
      global: 'Global Usage',
      titleExample: 'e.g. Agent Number (6 digits)',
      descriptionExample: 'e.g. Please enter your 6-digit Agent Number',
      validation: 'Validation',
      formatExample: 'e.g. ######',
      maxChars: 'max characters',
      chars: 'characters',
      ignoreCase: 'Ignore case',
      extendedOptions: 'Advanced validation methods',
      simpleOptions: 'Simple validation methods',
      reservedChars: 'Reserved characters',
      hash: 'Hash',
      percent: 'Percent',
      dot: 'Dot',
      star: 'Star',
      plus: 'Plus',
      questionMarkShort: 'Q. Mark',
      pipe: 'Pipe',
      hashDescription: 'A digit (0-9)',
      percentDescription: 'Any uppercase or lowercase letter',
      dotDescription: 'Any character',
      starDescription: 'Zero or more occurrences of the preceding expression',
      plusDescription: 'One or more occurrences of the preceding expression',
      questionMarkDescription: 'Zero or one occurrence of the preceding expression',
      setDescription: 'A character that is in the set',
      notSetDescription: 'A character that is not in the set',
      charsExactDescription: 'Exactly n occurrences of the preceding expression',
      charsAtLeastDescription: 'At least n occurrences of the preceding expression',
      charsBetweenDescription: 'Between n and m occurrences of the preceding expression',
      pipeDescription: 'Alternatives (either the preceding expression or the following expression)',
      groupDescription: 'A group of expressions',
      position: 'Position',
      inputField: 'Input Field',
      ignoreCaseShort: 'IC',
      usedInAfUf: 'Used in AF/UF',
      simpleExamplesModalTitle: 'Simple Validation Methods - Examples',
      complexExamplesModalTitle: 'Advanced Validation Methods - Examples',
      exampleModalTexts: {
        hashDescription: 'Represents six digits',
        hashExample: 'e.g. 123456',
        percentDescription: 'Represents three uppercase or lowercase letters',
        percentExample: 'e.g. aBC',
        dotDescription: 'Represents any two characters',
        dotExample: 'e.g. A1 or 2!',
        anyDigitsDescription: 'Represents zero or more digits',
        anyDigitsExample: 'e.g. nothing or 123',
        atLeastOneDigitDescription: 'Represents one or more digits',
        atLeastOneDigitExample: 'e.g. 1 or 1234567',
        optionalDigitDescription: 'Represents zero or one digit',
        optionalDigitExample: 'e.g. nothing or 9',
        twoLettersAnyDigitsDescription: 'Represents 2 letters and zero or more digits',
        twoLettersAnyDigitsExample: 'e.g. AT, bD5, XY456778879',
        letterRegexDescription: 'Represents one uppercase or lowercase letter without umlauts',
        charRangeDescription: 'Represents 4, 5, 6, or P',
        charSet: 'Represents J or N',
        notCharsDescription: 'Represents a character that is not a, b, c, A, B, or C',
        notCharsExample: 'e.g. 5, X, $',
        digitsExactDescription: 'Represents a sequence of 6 digits',
        digitsExactExample: 'e.g. 123456',
        digitsBetweenDescription: 'Represents a sequence of 4 to 6 digits',
        digitsBetweenExample: 'e.g. 1234, 12345, 123456',
        digitsOrDescription: 'Represents a sequence of 4 or 6 digits',
        digitsOrExample: 'e.g. 1234, 123456',
        yesOrNoExpression: '(Yes)|(No)',
        yesOrNoDescription: 'Represents Yes or No'
      },
      helpDescription: 'Checking whether an input corresponds to the desired format is done in programming with "<a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Cheatsheet" target="_blank">regular expressions</a>". If you already have experience with this, you can create your test formula yourself and try it out. Otherwise, we will be happy to provide you with advice and assistance.',
      helpTitle: 'Validation Help',
      needHelp: 'I need help with setup',
      previewTitle: 'Preview and Test',
      notValid: 'This expression is not valid',
      valid: 'This expression is valid',
      checkInput: 'Check Input',
      helpMailSubject: 'Assistance with setting up custom input fields',
      helpMailText: 'Customer ID: {customerId}\n' +
        'Username: {username}\n' +
        'Name: {name}\n' +
        'Phone: {phone}\n' +
        'Email: {mail}\n' +
        '\n' +
        'Dear CSN Team,\n' +
        '\n' +
        'I need help setting up a custom input field:\n' +
        '\n' +
        'My current entries are as follows:\n' +
        '\n' +
        'Label: {title}\n' +
        'Instruction: {tooltip}\n' +
        'Validation (optional): {validation}\n' +
        'Maximum character count (optional): {maxChars}\n' +
        'Ignore case (optional): {ignoreCase}\n' +
        '\n' +
        'If you need help creating the validation formula, please provide an example of a valid input:\n' +
        '\n' +
        'We will create the input field with the valid formula directly in your account.\n' +
        '\n' +
        'If you have any further questions regarding custom input fields, please describe them here in this email:',
      reservedCharsModalTitle: 'Reserved Characters in Validation',
      reservedCharsModalText: 'The following characters must be "escaped" in the validation formula to deactivate their special meaning within the regular expression and treat them as normal characters: <br><br> \\ ^ $ # % . | [ ] ( ) { } * + ? / <br><br>Escaping these characters is done by preceding them with a backslash "\\"',
      validationTextTooLong: 'The input is too long',
      invalidPattern: 'The given pattern is not valid',
      moveUp: 'Move up',
      moveDown: 'Move down',
      cantMakeLocal: 'Global usage cannot be deactivated because the field is used in forms of the following accounts: ',
      cantDelete: 'This field cannot be deleted because it is used in forms for the following accounts: ',
      cantDeleteUsed: 'This.field cannot be deleted, because it is used in the following forms: ',
      cantDeleteGlobal: 'This field cannot be deleted because it was provided centrally by your company\'s account management.',
      cantEditGlobal: 'This field cannot be edited because it was provided centrally by your company\'s account management.',
      inputInvalid: '{name} does not correspond to the specified format. Hover your mouse over the input field to see filling instructions.',
      usedBy: 'Used by: ',
      fieldDeleted: 'Inputfield successfully deleted'

    }
  },
  brandings: {
    editBranding: 'Edit Branding',
    newBranding: 'New Branding',
    saveBranding: 'Save Branding',
    namePlaceholder: 'Enter Branding Title',
    nameInvalid: 'Enter a name for the branding.',
    global: 'Global',
    logo: 'Logo',
    dataTypes: 'File Types: JPG, GIF, PNG, SVG',
    width: 'Width: {width}px (recommended)',
    height: 'Height: variable',
    left: 'left',
    middle: 'middle',
    right: 'right',
    selectPicture: 'Select Picture',
    orMoveHere: 'or move here',
    banner: 'Banner',
    headerColors: 'Header Colors',
    headerColorsDesc: 'For title, subtitle',
    text: 'Text',
    background: 'Background',
    accentColor: 'Accent Colors',
    accentColorDesc: 'For dates, icon, button',
    areaCode: 'Country Code',
    legal: 'Legal',
    privacy: 'Privacy Policy',
    footer: 'Footer',
    preview: 'Preview',
    logoSaved: 'Logo saved!',
    bannerSaved: 'Banner saved!',
    brandingSaved: 'Branding saved!',
    createBranding: 'Create a new Branding',
    noLogo: 'No Logo',
    noBanner: 'No Banner',
    brandingUsed: 'Branding is used in form(s)',
    deleteBranding: 'Delete Branding',
    brandingDeleted: 'Branding deleted',
    globalBranding: 'Global Branding',
    usedInForms: 'Not used in any form | Used in one form | Used in {count} forms',
    noAccess:
      'For a professional presentation of login and survey forms, CSN offers extensive branding features. The operation is easy and offers a lot of flexibility for customization in your corporate design or that of your partners and promotions. The number of brandings is not limited. Our graphics team supports you in creating the first design.',
    bookAccess: 'To unlock CSN branding features',
    previewTitle: 'Event Title',
    previewDates: 'Date Overview',
    previewData: 'Personal Data',
    previewRegister: 'Register'
  },
  filter: {
    tableView: 'Table View',
    cardsView: 'Cards View',
    search: 'Search',
    sort: 'Sort',
    newFormsDesc: 'new forms first',
    newFormsAsc: 'new forms last',
    newCardsDesc: 'new business cards first',
    newCardsAsc: 'new business cards last',
    newPresentationsDesc: 'new presentations first',
    newPresentationsAsc: 'new presentations last',
    newbackgroundsDesc: 'new backgrounds first',
    newBackgroundsAsc: 'new backgrounds last',
    lastEditDesc: 'last edit first',
    lastEditAsc: 'last edit last',
    alphabeticalAsc: 'alphabetical ascending (A-Z)',
    alphabeticalDesc: 'alphabetical descending (Z-A)',
    entriesPerPage: 'Entries/Page',
    allEntriesPerPage: 'All Entries/Page',
    activate: 'Activate',
    deactivate: 'Deactivate',
    activated: 'Activated',
    deactivated: 'Deactivated',
    from: 'from',
    shown: 'Displayed',
    elements: 'Elements',
    page: 'Page',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    invertSelection: 'Invert selection',
    changeSelection: 'Change selection',
    selectAdmins: 'Select moderators only',
    selectParticipants: 'Select participants only'
  },
  operator: {
    servies: 'Operator Services',
    selectedOperator: 'Selected Operator',
    noOperatorSelected: 'No operator selected',
    changeOperator: 'Change Operator',
    selectOperator: 'Select Operator',
    noOperator: 'No Operator',
    changeSuccess: 'Operator booking successfully updated',
    bookOperator: 'Book Operator Service',
    editOperator: 'Edit Operator Booking',
    noBookings: 'No Bookings',
    conferenceBooked: 'Conference booked',
    conferenceNotBooked: 'Conference not booked',
    contactOperator: 'Contact Operator',
    cancel: 'Cancel',
    durationPreMeeting: 'Briefing',
    cancelled: 'Operator booking successfully cancelled!',
    cancelOperatorBook: 'Cancel Operator Service booking',
    saveChanges: 'Save Changes',
    bookNow: 'Book Now',
    backToOverview: 'Back to Operator Service Overview',
    bookingDescriptionTitle: '"All-round Carefree Service" for our customers',
    bookingDescription:
      'Our operator team supports you with all topics related to your telephone and multimedia conference. We set up the conference with you, accompany you as the operator in the introduction, conduct a question and answer session, and more. Customize your operator service according to your needs.',
    bookingChanged: 'Booking successfully changed',
    booked: 'Operator service successfully booked',
    alreadyAssigned:
      'The selected conference is already assigned to another operator service.',
    formInvalid:
      'Please correct the highlighted errors or complete the page.',
    agents: {
      title: 'Speakers',
      description: 'Who moderates the conference?',
      minOneAgent: 'Please add at least one speaker.',
      agent: 'Speaker'
    },
    date: {
      title: 'Conference Date',
      description: 'When do you need the operator?',
      description2:
        'Automatic prefilling if a conference or registration form date has been assigned under point 1.',
      description3:
        'Please note that only one date is allowed per booking. Existing bookings can be used as templates.',
      expectedParticipants: 'Expected Number of Participants',
      expectedParticipantsShort: 'Expected Part.',
      caMinute: 'approx. {min} min.',
      caHour: 'approx. {hour} hr.',
      beforeMinute: '{min} min. before'
    },
    organisation: {
      title: 'Organization',
      description:
        'Who organizes the conference and can be reached for questions?',
      conferencePin: 'Conference PIN',
      sixDigits: 'six digits',
      participantPin: 'Telephone and data conference PIN for participants',
      language: 'Conference Language',
      languageDescription:
        'Language for system announcements and communication with the operator',
      organisator: 'Organizer',
      notes: 'Remarks / Data Account',
      notesPlaceholder:
        'Please enter the username of your data account here, if it differs from the audio account. Do you have any further questions or wishes regarding the conference?'
    },
    conference: {
      title: 'Conference Planning',
      description: 'Has the conference already been prepared in the account?',
      description2:
        'Has the conference already been booked in the system? Are you using a registration or survey form? Then make your selection here.',
      assignConference: 'Assign Conference',
      room: 'Conference Room',
      noConference: 'no conference assigned',
      assignRegForm: 'Assign Registration Form Date',
      noDate: 'no date assigned',
      assignSurveyForm: 'Assign Survey Form Date',
      noForm: 'no form assigned'
    },
    services: {
      title: 'Services',
      titleLong: 'Operator-Services',
      description: 'What can the operator do for you?',
      conferenceInit: 'Initiation of the Conference',
      conferenceInitDescription: 'Setup of the conference and registration form',
      systemTest: 'System Test with Moderator(s)',
      systemTestDescription: 'Phone, headset, microphone, webcam, browser, ...',
      preTalk: 'Preliminary talk with Moderator(s)',
      preTalkDescription: 'Coordination, sound check, and technical support',
      groups: 'Group connection after pre-talk',
      groupsDescription: 'Dialing starts only after your approval',
      moderation: 'Introduction',
      moderationDescription: 'Greetings to the participants',
      qa: 'Question and Answer Session',
      qaDescription: 'Moderation and participant approval',
      recording: 'Recording of the Conference (€7.95)',
      recordingDescription: 'Provided as an MP3 file',
      hosting: 'CSN Video Creation, Hosting (€75)',
      hostingDescription: 'and synchronization by CSN (€25)'
    },
    confType: {
      title: 'Conference Type',
      description: 'Which type of conference should be moderated?',
      selectMinOne: 'Please select at least one type.',
      dialOut: 'Dial-Out',
      dialOutDescription: 'Participants will be called',
      dialIn: 'Dial-In',
      dialInDescription: 'Participants dial in themselves',
      presentation: 'Data Conference',
      presentationDescription: 'Online presentation, screen sharing, chat, ...',
      liveVideo: 'Live Video',
      liveVideoDescription: 'Live video images of the speakers'
    }
  },
  pinReservation: {
    pinReservation: 'PIN Reservations',
    subtitle: '{pins} PIN reserved, of which {used} are used',
    subtitlePlural: '{pins} PINs reserved, of which {used} are used',
    searchPin: 'Search PINs',
    yourExclusivePin: 'Your Exclusive PIN',
    longDescription: 'By reserving PINs, you secure exclusive access codes and ensure safety in planning your events. A reservation is valid for one year and can be renewed as often as you like.',
    reserve: 'Reserve',
    descriptionShort: '6-digit PIN',
    errorDescription: '6-digit PIN made of numbers',
    pinCreated: 'PIN has been reserved',
    pin: 'PIN',
    usage: 'Usage',
    reservationTime: 'Days remaining',
    emptyTable: 'No PINs are reserved',
    emptySearch: 'No PINs match the search',
    nDays: '{count} day | {count} days',
    nHours: '{count} hour | {count} hours',
    renew: 'Renew',
    deleteConfirmModalOk: 'Delete Reservation',
    deleteConfirmModalTitle: 'Delete PIN Reservation',
    deleteConfirmModalText: 'Do you really want to delete the PIN reservation?',
    selectedReservations: 'Selected Reservations: {n}',
    pinAlreadyExists: 'The selected PIN is already assigned'
  },
  blacklist: {
    title: 'Blacklist',
    description:
      'The blacklist is a blocklist for phone numbers and email addresses in the registration and survey area. To block entire ranges, * is supported as a wildcard.',
    blockNumber: 'Block Phone Number',
    blockNumberExample: 'e.g., +49 1* or +49 211 7306340',
    blockEmail: 'Block Email',
    blockEmailExample: 'e.g., test@csn.de or *@test.de',
    invalidNumber: 'Invalid phone number',
    successful: 'Blacklist successfully updated!'
  },
  presentation: {
    speakerProfiles: {
      title: 'Business cards',
      nCards: '{n} Card | {n} Cards',
      saveSpeaker: 'Save Speaker',
      newSpeaker: 'New Speaker',
      newBusinessCard: 'New Business card',
      editSpeaker: 'Edit Speaker',
      editBusinessCard: 'Edit Business card',
      duplicateBusinessCard: 'Duplicate Business card',
      firstAndLastname: 'First & Last Name',
      position: 'Position',
      color: 'Color of the card',
      noSpeakerProfiles: 'There are no speaker profiles available.',
      sortAlphabetical: 'Sort Alphabetically',
      sortDateCreated: 'Last Edited First',
      sortLatest: 'Newest First',
      deleteConfirmModal: 'Are you sure you want to delete the business card of {name}?',
      deleteConfirmModalOk: 'Delete business card',
      deleteConfirmModalTitle: 'Delete business card',
      companyLogo: 'Company Logo',
      photo: 'Photo',
      homepage: 'Homepage',
      customButtons: 'Custom Buttons',
      buttonDescription: 'Buttons are links to external services such as appointment booking',
      nameOfButton: 'Button Name',
      urlOfButton: 'Button URL',
      addButton: 'Add Button',
      saveButton: 'Save button',
      buttonPlaceholder: 'Appointment Booking',
      activateProfile: 'Activate Profile',
      openProfile: 'Open profile',
      photoCanBeMoved: 'The photo can be moved with the mouse',
      infobox: 'Infobox',
      socialLinksTitle: 'Social Media Links',
      socialNetwork: 'Network',
      urlOfProfile: 'Profile URL',
      addNetwork: 'Add Network',
      saveNetwork: 'Save Network',
      selectNetwork: 'Select Network',
      wrongPlatform: 'The link does not match the selected platform or does not contain a profile',
      networks: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        vimeo: 'Vimeo',
        x: 'X',
        xing: 'Xing',
        youtube: 'YouTube',
        linkedin: 'LinkedIn'
      },
      deleteSpeaker: 'Delete business card',
      businessCard: 'Business card',
      noSpecialChars: 'Only letters, numbers and _.- are allowed',
      invalidUrl: 'invalid Url',
      selectPlatform: 'Please select a platform',
      extendedProfile: 'Extended Profile',
      extendedProfilesDescription: 'Extended profiles can be viewed by participants during an event. They are also accessible on the internet beyond the event, under the URL you specify.',
      urlChanged: 'Please note that if the URL path is changed, the original QR code will no longer work. This might be relevant if you have integrated the QR code, for example, in an email signature.',
      resetUrl: 'Revert to the original URL path',
      saveToTest: 'To test the function, the business card must be saved',
      lineBreakDescription: 'Line break with "|"',
      addressPlaceholder: 'Sample Street 100, D-40213 Düsseldorf',
      homepagePlaceholder: 'https://www.sample.com',
      deletedToast: 'Business card successfully deleted',
      activateCard: 'Activate Card',
      deactivateCard: 'Deactivate Card',
      noActiveCards: 'No active business cards',
      noInactiveCards: 'No inactive business cards',
      activeCards: 'Active Business Cards',
      inactiveCards: 'Inactive Business Cards'
    },
    liveVideoBackgrounds: {
      liveVideoBackgrounds: 'Video Backgrounds',
      description: 'Background images for conferences',
      nImages: '{count} image | {count} images',
      addNewImage: 'Add New Image',
      editTitle: 'Edit Background Name',
      backgroundImage: 'Background Image',
      title: 'Image Name',
      deletedToast: 'Background successfully deleted',
      deleteConfirmModalOk: 'Delete Background',
      deleteConfirmModalTitle: 'Delete Video Background',
      deleteConfirmModalText: 'Are you sure you want to delete the video background?',
      deleteMultipleConfirmModalOk: 'Delete backgrounds',
      deleteMultipleConfirmModalTitle: 'Delete video backgrounds',
      deleteMultipleConfirmModalText: 'Are you sure you want to delete the selected video backgrounds?',
      createModalDescription: 'For best results, select an image in 16:9 format with a resolution of at least 1280 x 720 pixels',
      ratioWarning: '<strong>Warning</strong>: The provided image is not in a 16:9 format and will be cropped.',
      sizeWarning: '<strong>Warning</strong>: The image does not meet the quality standards!',
      imageUploadFailed: 'The image could not be processed.',
      createYourOwn: 'Create your own video background',
      minimumResolution: 'Minimum resolution: {x} x {y} pixels',
      selectedBackgrounds: 'Selected backgrounds',
      extendedProfilesDescription: 'Extended profiles can be viewed by participants during an event. They are also accessible on the internet beyond the event, under the URL you specify.'
    },
    waitingRoomBranding: {
      waitingRoomBranding: 'Waiting Room Branding',
      waitingRoomBrandings: 'Waiting Room Brandings',
      nBrandings: '{count} Branding | {count} Brandings',
      duration: 'Duration',
      content: 'Waiting Room Content',
      title: 'Waiting Room Title',
      presentation: 'Waiting Room Presentation',
      durationPerElement: 'Display Duration per Element',
      showCountdown: 'Show Countdown',
      music: 'Waiting Room Music',
      customMusic: 'Custom Music',
      audioFileDescription: 'Audio file in MP3 or WAV format',
      audioFileSize: 'max. 100 MB',
      audioVideoDescription: 'The music will be interrupted when a video is played in the presentation.',
      noBrandings: 'No brandings available',
      edit: 'Edit Waiting Room',
      delete: 'Delete Waiting Room',
      selectPresentation: 'Please select a presentation',
      selectedBrandings: 'Selected Brandings',
      noPresentation: 'No presentation assigned',
      noElements: 'Presentation contains no elements',
      noMusic: 'No audio files available',
    },
    name: 'Name',
    lastModified: 'Last Modified',
    global: 'Global',
    setGlobal: 'Unlock globally',
    elements: 'Elements',
    animations: 'Animations',
    selectedPresentations: 'Selected Presentations',
    selectedMaterials: 'Selected Materials',
    selectedDownloads: 'Selected Files',
    noPresentations: 'No presentations available',
    presentations: '{n} presentation | {n} presentations',
    newPresentation: 'New Presentation',
    editPresentation: 'Edit Presentation',
    pinForMods: 'PIN for moderators',
    pinForParticipants: 'PIN for participants',
    sixDigitPin: '6-digit PIN',
    info: 'You can create up to 50 presentations. To keep an overview, unused presentations are deleted after 12 months.',
    title: 'Title of the presentation',
    savedSuccessfully: 'Presentation saved successfully!',
    elementSaved: 'Material successfully edited.',
    materials: 'Materials ({n})',
    material: 'Material',
    materialName: 'Material Name',
    fileTooLarge: 'The file is too large. The maximum file size is 100 MB.',
    downloads: 'Downloads ({n})',
    documents: 'Documents ({n})',
    documentsDescription: 'Powerpoint, PDF, Videos, Images (max 100 MB)',
    videoLinks: 'Video-URLs ({n})',
    videoLinksDescription: 'Integration of videos from YouTube or Vimeo',
    surveys: 'Surveys ({n})',
    surveysDescriptionEn: 'Integrate survey forms, create spontaneous surveys',
    addSurvey: 'Add Survey',
    updateSurvey: 'Update Survey',
    addSurveyDescription: 'Would you like to add a spontaneous survey or a survey form? Spontaneous surveys are used for quick opinion formation including graphical evaluation within the event. Survey forms are usually more detailed, but the results are only available after the event.',
    addSurveyFormDescription: 'If there is no survey form available yet, save your current setup and create a new survey form in the "Forms" > "Surveys" area. Then return here.',
    notesCount: 'Notes ({n})',
    notes: 'Notes',
    cantDeleteBrandingsExist: 'This presentation cannot be deleted because it is used Waiting-Room Brandings.',
    import: {
      WAITING: 'Waiting for import...',
      FINISHED: 'Import finished',
      CONVERTING: 'Converting...',
      ERROR: 'Error during import',
      idle: 'Waiting for import...',
      starting: 'Starting import...',
      running: 'Import in progress...',
      finished: 'Import finished',
      error: 'Error during import',
      parsing: 'Import in progress...',
      copied: 'Waiting for import...'
    },
    document: {
      IMAGE: 'Image',
      PDF: 'PDF',
      POWERPOINT: 'Powerpoint',
      PPTX: 'Powerpoint',
      WEBVIDEO: 'Web-Video',
      SURVEY_FORM: 'Survey form',
      VIDEO: 'Video',
      SURVEY: 'Spontaneous survey',
      uploaded: 'Document successfully uploaded. It will be processed shortly.'
    },
    download: {
      title: 'Materials for Participants',
      description: 'Downloads are files that can be made available for download in an event. Some materials such as Powerpoint, Word, or PDF files are automatically transferred to the download area. In addition, further files can be uploaded (max 100 MB).',
      added: 'File successfully added',
      deleted: 'File successfully deleted',
      delete: 'Delete File',
      downloadFile: 'Download File'
    },
    survey: {
      spontaneous: 'Spontaneous Survey',
      formDe: 'Umfrageformular',
      form: 'Survey Form',
      explanation: 'Explanation of the question',
      explanationPlaceholder: 'Would you like to explain your question further?',
      questionPlaceholder: 'What is your question?',
      questionType: 'Question Type',
      singleChoice: 'Single-Choice',
      multipleChoice: 'Multiple-Choice',
      showResults: 'Show results to participants',
      pieChartOption: 'Pie Chart (with percentage)',
      barChartVOption: 'Vertical Bar Chart',
      barChartHOption: 'Horizontal Pie Chart',
      absoluteNumbers: 'Absolute Numbers',
      percentage: 'Percentage',
      noDescription: 'No Description',
      added: 'Survey successfully added'
    },
    webVideo: {
      added: 'Web video added',
      invalidUrl: 'Enter a valid YouTube or Vimeo URL.',
      videoDoesNotExist: 'The video does not exist or is not available.'
    }
  },
  days: {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday'
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
  },
  login: {
    namePlaceholder: 'First and Last Name',
    emailPlaceholder: 'Username or Email Address',
    interested: 'Interest piqued?',
    invalidLogin: 'You are trying to log in as {loginMode}.',
    offer: 'The first appointment is on us',
    noCustomerYet: 'Not a customer yet?',
    here: 'Here',
    moreInformation: 'you can get more information.',
    slides: {
      testNow: 'Test Now for Free',
      inhouse: 'Inhouse',
      customerService: 'Customer Service',
      brilliant: 'Brilliant',
      quality: 'Voice Quality',
      premium: 'Premium',
      operator: 'Operator Service',
      intuitive: 'Intuitive',
      experience: 'User Guidance',
      one: {
        header: 'Welcome to CSN',
        gutBeraten: {
          text: '"Gut beraten" Training',
          description: 'We eliminate bureaucracy'
        },
        webinar: {
          text: 'Webinars',
          description: 'From registration to testing'
        },
        livestream: {
          text: 'Professional Livestreams',
          description: 'HD quality from the TV studio'
        },
        calls: {
          text: 'Sales and recruiting calls',
          description: 'Promote your products'
        },
        events: {
          text: 'Virtual Events',
          description: 'We fulfill your wishes'
        }
      },
      two: {
        header: '"Gut beraten" Training via CSN',
        text1: 'Inspire your participants with...',
        bulletPoints: {
          one: '<span class="text-primary font-weight-medium">diverse</span> training sessions',
          two: 'a <span class="text-primary font-weight-medium">quick minute credit</span>',
          three: 'an individual <span class="text-primary font-weight-medium">success certificate</span>'
        },
        text2: 'All <span class="text-primary font-weight-medium">automated</span> through our platform.'
      },
      three: {
        header: 'Efficient Webinars',
        bulletPoints: {
          one: 'Attractive marketing through registration forms',
          two: 'Exciting training content',
          three: 'Knowledge tests with automatic university-standard evaluation',
          four: 'Individual participant certificates'
        }
      },
      four: {
        header: 'HD Livestreams Directly from the TV Studio',
        text1: 'Maximize your persuasive power, without a net and safety net.',
        text2: 'Don\'t have your own studio?<br>We can help you out!'
      },
      five: {
        header: 'We Are Here for You',
        uncompromising: {
          text: 'Uncompromising',
          description: 'We fulfill your wishes'
        },
        individualization: {
          text: 'Customizability',
          description: 'Optional in your design'
        },
        training: {
          text: 'Free Training',
          description: 'For new customers and users'
        },
        operatorService: {
          text: 'Premium Operator Service',
          description: 'We take care of the rest'
        },
        standards: {
          text: 'Highest Standards',
          description: 'Focus on data protection'
        }
      },
      six: {
        header: 'Our References'
      }
    },
    login: 'Login',
    pin: 'PIN',
    errors: {
      invalidUsernameOrPassword: 'Invalid username or password.',
      falseName: 'Your name must be at least two characters long!',
      noPresentation: 'Presentation does not exist.',
      maxLimit: 'Login not possible, as the participant limit for this event has been reached.',
      notStarted: 'The presentation has not started yet!',
      sessionLocked: 'The conference room has been locked by the moderator.',
      insertNameAndPin: 'Please enter name and PIN.'
    },
    passwordChanged: 'Password changed successfully!'
  },
  fileType: {
    pdf: 'PDF Document',
    word: 'Word Document',
    excel: 'Excel Spreadsheet',
    powerpoint: 'PowerPoint Presentation',
    odt: 'OpenDocument Text',
    ods: 'OpenDocument Spreadsheet',
    odp: 'OpenDocument Presentation',
    rtf: 'Rich Text Format',
    image: 'Image File',
    vectorImage: 'Vector Image (SVG)',
    audio: 'Audio File',
    video: 'Video File',
    archive: 'Archive File',
    text: 'Text File',
    csv: 'CSV File',
    html: 'HTML Document',
    css: 'CSS File',
    json: 'JSON File',
    javascript: 'JavaScript File',
    xml: 'XML File',
    sql: 'SQL Script',
    font: 'Font File',
    binary: 'Binary File',
    script: 'Script File',
    phpScript: 'PHP Script',
    pythonScript: 'Python Script',
    unknown: 'Unknown File Type'
  }
};
































































import Component from 'vue-class-component';
import WSwitch from '@/components/WSwitch.vue';
import Base from '@/views/Base';
import { Prop } from 'vue-property-decorator';
import { CreateWaitingRoomBranding, WaitingRoomBranding, WaitingRoomSong } from '@/models/WaitingRoomBranding';
import axios from 'axios';
import WaitingRoomBrandingService from '@/service/WaitingRoomBrandingService';
import MusicItem from '@/views/presentation/waitingRoomBranding/MusicItem.vue';

@Component({
  components: { MusicItem, WSwitch },
})
export default class MusicEdit extends Base {
  @Prop() waitingRoom!: WaitingRoomBranding;
  @Prop() createWaitingRoom!: CreateWaitingRoomBranding;

  audio = new Audio();
  currentSong: WaitingRoomSong | null = null;
  progress = 0;

  loading = false;

  playSong(song: WaitingRoomSong) {
    if (this.currentSong) {
      this.stopSong(this.currentSong);
    }

    if(song.id){
      this.audio = new Audio(axios.defaults.baseURL + '/waiting_room_branding/' + this.waitingRoom.id + '/music/' + song.filename);
    } else {
      this.audio = new Audio(axios.defaults.baseURL + '/waiting_room_branding/music/' + song.filename);
    }

    this.audio.play();
    this.progress = 0;
    this.audio.addEventListener('timeupdate', this.updateProgress);
    this.audio.addEventListener('ended', () => {
      this.currentSong = null;
      this.progress = 0;
    });

    this.currentSong = song;
  }

  stopSong(song: WaitingRoomSong) {
    this.audio.pause();
    this.currentSong = null;
    this.progress = 0;
  }

  updateProgress() {
    if (this.currentSong) {
      this.progress = (this.audio.currentTime / this.audio.duration) * 100;
    }
  }

  uploadMusic(files: File[]) {
    const file = files[0];
    this.loading = true;

    WaitingRoomBrandingService.uploadMusic(this.waitingRoom.id, file).then((music) => {
      this.$emit('updateWaitingRoom');
      this.createWaitingRoom.selectedCustomMusic.push(music.filename);
      this.updateSelectedMusic();
    }).catch(this.showNetworkError).finally(() => {
      this.loading = false;
    });
  }

  updateSelectedMusic() {
    this.$emit('input');
  }

}

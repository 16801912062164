


















































































































import { Component } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import Base from '@/views/Base';
import WSwitch from '@/components/WSwitch.vue';
import WProgressBar from '@/components/WProgressBar.vue';
import { ElementListItem, Presentation } from '@/models/Presentation';
import PresentationService from '@/service/PresentationService';
import { CreateWaitingRoomBranding, WaitingRoomBranding } from '@/models/WaitingRoomBranding';
import WaitingRoomBrandingService from '@/service/WaitingRoomBrandingService';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import ElementThumbnail from '@/views/presentation/components/ElementThumbnail.vue';
import MusicEdit from '@/views/presentation/waitingRoomBranding/MusicEdit.vue';

@Component({
  components: { MusicEdit, ElementThumbnail, WProgressBar, WSwitch, WHeaderBtn, HeaderLayout }
})
export default class WaitingRoomBrandingEdit extends Base {

  presentations: Presentation[] = [];

  presentationElements: ElementListItem[] = [];

  model: CreateWaitingRoomBranding = WaitingRoomBrandingService.getEmptyWaitingRoom();

  waitingRoom: WaitingRoomBranding = {
    id: 0,
    dateCreated: new Date(),
    title: '',
    global: false,
    defaultDuration: 0,
    showCountdown: false,
    totalDuration: 0,
    presentation: undefined,
    predefinedMusic: [],
    customMusic: []
  };

  @Validations()
  validations = {
    model: {
      title: {
        required
      },
      presentationId: {
        required
      }
    }
  };

  durationOptions = [
    { value: 15, text: '15 Sekunden' },
    { value: 30, text: '30 Sekunden' },
    { value: 60, text: '1 Minute' },
    { value: 120, text: '2 Minuten' },
    { value: 300, text: '5 Minuten' }
  ];

  presentationSelected() {
    this.submit();
    this.loadElements();
  }

  loadElements() {
    this.presentationElements = [];
    if (!this.model.presentationId) {
      return;
    }
    PresentationService.getElements(this.model.presentationId)
      .then(res => {
        this.presentationElements = res.map((el) => {
          return PresentationService.elementToListItem(el);
        });
      }).catch(this.showNetworkError);
  }

  get totalDuration() {
    return this.presentationElements.reduce((acc, el) => {
      return acc + (el.entity.duration || this.model.defaultDuration);
    }, 0);
  }

  get durationsPrefixSum(): number[] {
    const res = [];
    let sum = 0;
    for (const el of this.presentationElements) {
      sum += el.entity.duration || this.model.defaultDuration;
      res.push(sum);
    }

    return res;
  }

  async mounted() {
    PresentationService.getPresentations(0, 100, 'title', 'ASC')
      .then(res => {
        this.presentations = res.content;
      }).catch(this.showNetworkError);

    this.loadWaitingRoom();
  }

  loadWaitingRoom() {
    WaitingRoomBrandingService.getWaitingRoomBranding(Number(this.$route.params.id))
      .then(res => {
        this.setWaitingRoom(res);
        this.loadElements();
      }).catch(this.showNetworkError);
  }

  setWaitingRoom(res: WaitingRoomBranding) {
    this.waitingRoom = res;
    this.model = {
      title: res.title,
      global: res.global,
      defaultDuration: res.defaultDuration,
      showCountdown: res.showCountdown,
      presentationId: res.presentation?.id,
      selectedCustomMusic: (res.customMusic || []).filter(m => m.selected).map(m => m.filename),
      selectedPredefinedMusic: (res.predefinedMusic || []).filter(m => m.selected).map(m => m.filename)
    };
  }

  get currentPresentation(): Presentation | undefined {
    return this.presentations.find(p => p.id === this.model.presentationId);
  }

  formatDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  async submit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    if (!this.waitingRoom) {
      return;
    }

    return WaitingRoomBrandingService.updateWaitingRoom(this.waitingRoom.id, this.model)
      .then((res) => {
        this.setWaitingRoom(res);
        return res;
      }).catch(this.showNetworkError);
  }

  async exit() {
    await this.submit();
    this.$router.push('/presentation/waitingroom');
  }

}

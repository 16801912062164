import { CreateWaitingRoomBranding, WaitingRoomBranding, WaitingRoomSong } from '@/models/WaitingRoomBranding';
import axios from 'axios';

export default {
  getWaitingRoomBranding(id: number): Promise<WaitingRoomBranding> {
    return axios.get(`/waiting_room_branding/${id}`).then(response => response.data);
  },

  getWaitingRoomBrandings(): Promise<WaitingRoomBranding[]> {
    return axios.get('/waiting_room_branding').then(response => response.data);
  },

  createWaitingRoom(branding: CreateWaitingRoomBranding): Promise<WaitingRoomBranding> {
    return axios.post('/waiting_room_branding', branding).then(response => response.data);
  },

  updateWaitingRoom(id: number, branding: CreateWaitingRoomBranding): Promise<WaitingRoomBranding> {
    return axios.put(`/waiting_room_branding/${id}`, branding).then(response => response.data);
  },

  deleteWaitingRoom(id: number): Promise<void> {
    return axios.delete(`/waiting_room_branding/${id}`).then(response => response.data);
  },

  uploadMusic(id: number, file: File): Promise<WaitingRoomSong> {
    const form = new FormData();
    form.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios.post(`/waiting_room_branding/${id}/music`, form, {headers}).then(response => response.data);
  },

  getEmptyWaitingRoom(): CreateWaitingRoomBranding{
    return {
      title: 'Neuer Warteraum',
      defaultDuration: 60,
      showCountdown: false,
      global: false,
      selectedCustomMusic: [],
      selectedPredefinedMusic: [],
    }
  }
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home/Index.vue';
import Login from '../views/Login.vue';

import Profile from '../views/user/Profile.vue';
import Manual from '../views/user/Manual.vue';
import Extras from '../views/user/Extras.vue';
import Settings from '../views/user/Settings.vue';

import ConferencePast from '../views/conference/Past.vue';
import ConferenceRooms from '../views/conference/Rooms.vue';
import ConferenceScheduled from '../views/conference/Scheduled.vue';
import ConferenceScheduledItem from '../views/conference/Item.vue';
import ConferencePastItem from '../views/conference/PastItem.vue';
import ConferenceRunningItem from '../views/conference/RunningItem.vue';
import ConferenceBooking from '../views/conference/Booking/Index.vue';
import ConferenceRoomBooking from '../views/conference/Booking/RoomBooking.vue';

import FormsRegistrationNew from '../views/forms/RegistrationNew.vue';
import FormsRegistrationEdit from '../views/forms/RegistrationEdit.vue';
import FormsRegistrationItem from '../views/forms/RegistrationItem.vue';
import FormsRegistration from '../views/forms/Registration.vue';

import FormsSurveyNew from '../views/forms/SurveyNew.vue';
import FormsSurveyEdit from '../views/forms/SurveyEdit.vue';
import FormsSurvey from '../views/forms/Survey.vue';
import FormsSurveyItem from '../views/forms/SurveyItem.vue';

import FormsBranding from '../views/forms/Branding/Index.vue';
import FormsBrandingPreview from '../views/forms/Branding/Preview.vue';
import FormsBrandingForm from '../views/forms/Branding/Form.vue';

import OperatorBookingForm from '../views/operator/OperatorServiceForm.vue';
import Operators from '../views/operator/Index.vue';

import AdminNumbers from '../views/admin/Numbers.vue';
import AdminUsers from '../views/admin/Users.vue';
import AdminOperator from '../views/admin/Operator.vue';
import AdminUsersItem from '../views/admin/UsersItem.vue';
import AdminUsersNew from '../views/admin/UsersNew.vue';
import AdminCustomers from '../views/admin/Customers.vue';
import AdminCustomersItem from '../views/admin/CustomersItem.vue';
import AdminCustomersNew from '../views/admin/CustomersNew.vue';
import AdminConsoleRunning from '../views/admin/console/RunningConferences.vue';
import AdminConsoleScheduled from '../views/admin/console/ScheduledConferences.vue';
import AdminConsolePast from '../views/admin/console/PastConferences.vue';
import AdminRegistrationForms from '../views/admin/forms/RegistrationForms.vue';

import DocumentAdressbook from '../views/documents/Adressbook/Index.vue';
import DocumentAdressbookImport from '../views/documents/Adressbook/Import.vue';
import DocumentPlaybacks from '../views/documents/Playbacks.vue';

import UnderConstruction from '../views/UnderConstruction.vue';
import NotFound from '../views/NotFound.vue';
import Admins from '@/views/admin/Admins.vue';
import AdminsNew from '@/views/admin/AdminsNew.vue';
import AdminsItem from '@/views/admin/AdminsItem.vue';
import News from '@/views/user/News.vue';
import AdminSurveyForms from "@/views/admin/forms/SurveyForms.vue";

import SpeakerProfileIndex from "@/views/presentation/speakerProfiles/Index.vue"
import SpeakerProfileEdit from "@/views/presentation/speakerProfiles/Edit.vue"

import LiveVideoBackgroundIndex from "@/views/presentation/liveVideoBackgrounds/Index.vue"
import PinReservation from '@/views/documents/PinReservation/Index.vue'

import Presentation from "@/views/presentation/Presentation.vue";
import PresentationEdit from "@/views/presentation/PresentationEdit.vue";

import CustomValidationsOverview from "@/views/forms/customFields/Index.vue";
import CustomFieldItem from "@/views/forms/customFields/New.vue";
import WaitingRoomBrandingIndex from '@/views/presentation/waitingRoomBranding/Index.vue';
import WaitingRoomBrandingEdit from '@/views/presentation/waitingRoomBranding/WaitingRoomBrandingEdit.vue';

Vue.use(VueRouter);

const routes = [
	{ path: '/', name: 'Home', component: Home },
	{
		path: '/conference/scheduled',
		name: 'Conference-Scheduled',
		component: ConferenceScheduled
	},
	// meta: { authenticated: true, roles: ['USER'] } },
	{
		path: '/conference/past',
		name: 'Conference-Past',
		component: ConferencePast
	},
	{
		path: '/conference/rooms',
		name: 'Conference-Rooms',
		component: ConferenceRooms
	},
	{
		path: '/conference/scheduled/:id',
		name: 'Conference-Scheduled-Item',
		component: ConferenceScheduledItem
	},
	{
		path: '/conference/past/:id',
		name: 'Conference-Past-Item',
		component: ConferencePastItem
	},
	{
		path: '/conference/room/:id',
		name: 'Conference-Rooms-Item',
		component: ConferenceScheduledItem
	},
	{
		path: '/conference/running/:id',
		name: 'Conference-Running-Item',
		component: ConferenceRunningItem
	},
	// { path: '/teleconference/opservice', name: 'Conference-OpService', component: TeleconferenceOpService },
	{
		path: '/conference/booking',
		name: 'Conference-Booking',
		component: ConferenceBooking
	},
	{
		path: '/conference/booking-room',
		name: 'Conference-Room-Booking',
		component: ConferenceRoomBooking
	},

	{
		path: '/forms/registration/new',
		name: 'Forms-New-Registration',
		component: FormsRegistrationNew
	},
	{
		path: '/forms/registration/:id',
		name: 'Forms-Registration-Item',
		component: FormsRegistrationItem
	},
	{
		path: '/forms/registration/:id/edit',
		name: 'Forms-Edit-Registration',
		component: FormsRegistrationEdit
	},
	{
		path: '/forms/registration',
		name: 'Forms-Registration',
		component: FormsRegistration
	},

	{
		path: '/forms/survey/new',
		name: 'Forms-New-Survey',
		component: FormsSurveyNew
	},
	{
		path: '/forms/survey/:id',
		name: 'Forms-Survey-Item',
		component: FormsSurveyItem
	},
	{
		path: '/forms/survey/:id/edit',
		name: 'Forms-Edit-Survey',
		component: FormsSurveyEdit
	},
	{ path: '/forms/survey', name: 'Forms-Survey', component: FormsSurvey },

	{ path: '/forms/branding', name: 'Forms-Branding', component: FormsBranding },
	{ path: '/forms/branding/:id', name: 'Forms-Branding-Edit', component: FormsBrandingForm },
	{
		path: '/forms/branding/new',
		name: 'Forms-New-Branding',
		component: FormsBrandingForm
	},
	{
		path: '/forms/custom-fields',
		name: 'Custom-Fields',
		component: CustomValidationsOverview
	},
	{
		path: '/forms/custom-fields/:id',
		name: 'Custom-Fields-New',
		component: CustomFieldItem
	},
	{
		path: '/forms/branding/preview',
		name: 'Forms-Branding-Preview',
		component: FormsBrandingPreview
	},

	{
		path: '/documents/adressbook',
		name: 'Documents-Adressbook',
		component: DocumentAdressbook
	},
	{
		path: '/documents/adressbook/import',
		name: 'Documents-Adressbook-Import',
		component: DocumentAdressbookImport
	},
	{
		path: '/documents/playbacks',
		name: 'Documents-Playbacks',
		component: DocumentPlaybacks
	},

	{
		path: '/operator/new',
		name: 'Operator-Booking-New',
		component: OperatorBookingForm
	},
	{
		path: '/operator/:id',
		name: 'Operator-Booking-Edit',
		component: OperatorBookingForm
	},
	{ path: '/operator', name: 'Operator-Bookings', component: Operators },

	{
		path: '/admin/users/new',
		name: 'Management-Accounts-New',
		component: AdminUsersNew
	},
	{
		path: '/admin/users/:id',
		name: 'Management-Accounts-Item',
		component: AdminUsersItem
	},
	{ path: '/admin/users', name: 'Management-Accounts', component: AdminUsers },
	{
		path: '/admin/customers/new',
		name: 'Management-Customers-New',
		component: AdminCustomersNew
	},
	{
		path: '/admin/customers/:id',
		name: 'Management-Customers-Item',
		component: AdminCustomersItem
	},
	{
		path: '/admin/customers',
		name: 'Management-Customers',
		component: AdminCustomers
	},
	{
		path: '/admin/admins/new',
		name: 'Management-Admins-New',
		component: AdminsNew
	},
	{
		path: '/admin/admins/:id',
		name: 'Management-Admins-Item',
		component: AdminsItem
	},
	{ path: '/admin/admins', name: 'Management-Admins', component: Admins },

	{
		path: '/admin/console/running',
		name: 'Report-Console-Running',
		component: AdminConsoleRunning
	},
	{
		path: '/admin/console/scheduled',
		name: 'Report-Console-Scheduled',
		component: AdminConsoleScheduled
	},
	{
		path: '/admin/console/past',
		name: 'Report-Console-Past',
		component: AdminConsolePast
	},
	{
		path: '/admin/operator',
		name: 'Report-Operator',
		component: AdminOperator
	},
	{
		path: '/admin/forms/registration',
		name: 'Report-Registration-Forms',
		component: AdminRegistrationForms
	},
	{
		path: '/admin/forms/survey',
		name: 'Admin-Surveys',
		component: AdminSurveyForms
	},

	{ path: '/admin/numbers', name: 'System-Numbers', component: AdminNumbers },

	{ path: '/profile', name: 'User-Profile', component: Profile },
	{ path: '/manual', name: 'User-Manual', component: Manual },
	{ path: '/extras', name: 'User-Extras', component: Extras },
	{ path: '/news', name: 'User-News', component: News },
	{ path: '/settings', name: 'User-Settings', component: Settings },

	{ path: '/presentation/speaker-profile', name: 'Presentation-Speaker-Profile', component: SpeakerProfileIndex },
	{ path: '/presentation/speaker-profile/:id', name: 'Presentation-Speaker-Profile-Edit', component: SpeakerProfileEdit },

	{ path: '/presentation/waitingroom', name: 'Waiting-Room-Branding-Index', component: WaitingRoomBrandingIndex },
	{ path: '/presentation/waitingroom/:id', name: 'Waiting-Room-Branding-Edit', component: WaitingRoomBrandingEdit },

	{ path: '/presentation/live-video-backgrounds', name: 'Live-Video-Background-Index', component: LiveVideoBackgroundIndex },

	{ path: '/presentation', name: 'Presentation', component: Presentation },
	{ path: '/presentation/:id', name: 'Presentation-Edit', component: PresentationEdit },

	{path: '/documents/pin', name: 'Pin-Reservation', component: PinReservation},

	{
		path: '/login',
		name: 'Login',
		meta: { layout: 'login' },
		component: Login
	},
	{
		path: '/under-construction',
		name: 'UnderConstruction',
		component: UnderConstruction
	},
	{ path: '/*', name: 'NotFound', component: NotFound }
];

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((from, to, next) => {
	next();
});

export default router;

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { WaitingRoomSong } from '@/models/WaitingRoomBranding';
import WSwitch from '@/components/WSwitch.vue';

@Component({
  components: { WSwitch }
})
export default class MusicItem extends Vue {

  @Prop() model!: string[];
  @Prop({ required: true, type: Object }) song!: WaitingRoomSong;
  @Prop({ required: false, default: null }) currentSong!: WaitingRoomSong | null;
  @Prop({ required: false, type: Number, default: 0 }) progress!: number;

  updateModel(value: boolean) {
    if (value) {
      this.model.push(this.song.filename);
    } else {
      const idx = this.model.indexOf(this.song.filename);
      if (idx > -1) {
        this.model.splice(idx, 1);
      }
    }
    this.$emit('input');
  }

  get checked(): boolean {
    return this.model.includes(this.song.filename);
  }

  formatDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

}























































































import { Component, Watch } from 'vue-property-decorator';
import Base from '@/views/Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import { WaitingRoomBranding } from '@/models/WaitingRoomBranding';
import WaitingRoomBrandingService from '@/service/WaitingRoomBrandingService';
import { round } from 'lodash';
import ElementPlayerModal from '@/views/presentation/components/ElementPlayerModal.vue';
import WaitingRoomPreview from '@/views/presentation/waitingRoomBranding/WaitingRoomPreview.vue';

@Component({
  methods: { round },
  components: { WaitingRoomPreview, ElementPlayerModal, WListOptions, HeaderLayout }
})
export default class WaitingRoomBrandingIndex extends Base {

  brandings: WaitingRoomBranding[] = [];

  currentSelectOption = '';

  options = {
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC'
  };

  selectedBrandings: number[] = [];

  mounted() {
    this.loadBrandings();
  }

  @Watch('options.searchTerm')
  searchUpdated() {
    this.loadBrandings();
  }

  sort() {
    this.loadBrandings();
  }

  async loadBrandings() {
    WaitingRoomBrandingService.getWaitingRoomBrandings().then(res => {
      this.brandings = res;
    }).catch(this.showNetworkError);
  }

  async updateBranding(id: number) {
    await this.$router.push('/presentation/waitingroom/' + id);
  }

  async createNewBranding() {
    const branding = await WaitingRoomBrandingService.createWaitingRoom(WaitingRoomBrandingService.getEmptyWaitingRoom());
    await this.$router.push('/presentation/waitingroom/' + branding.id);
  }

  async deleteBranding(id: number) {
    const name = this.brandings.find(b => b.id === id)?.title;
    if (!name) return;
    this.$bvModal.msgBoxConfirm(this.$t('modals.deleteWaitingRoomBranding.descriptionSingle', { name }) as string, {
      title: this.t('modals.deleteWaitingRoomBranding.title'),
      size: 'md',
      okVariant: 'danger',
      okTitle: this.t('modals.deleteWaitingRoomBranding.okSingle'),
      cancelTitle: this.t('common.cancel'),
      centered: true
    })
      .then(value => {
        if (value) {
          WaitingRoomBrandingService.deleteWaitingRoom(id).then(() => {
            this.loadBrandings();
          }).catch(this.showNetworkError);
        }
      });
  }

  deleteSelected() {
    this.$bvModal.msgBoxConfirm(this.t('modals.deleteWaitingRoomBranding.description'), {
      title: this.t('modals.deleteWaitingRoomBranding.title'),
      size: 'md',
      okVariant: 'danger',
      okTitle: this.t('modals.deleteWaitingRoomBranding.ok'),
      cancelTitle: this.t('common.cancel'),
      centered: true
    })
      .then(value => {
        if (value) {
          this.selectedBrandings.forEach(id => {
            WaitingRoomBrandingService.deleteWaitingRoom(id).then(() => {
              this.loadBrandings();
            }).catch(this.showNetworkError);
          });
        }
      });
  }

  openPreviewModal(branding: WaitingRoomBranding) {
    const preview = this.$refs['wr-preview'] as any;
    preview.showPreview(branding);
  }

  formatDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

}

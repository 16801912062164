









































import { Component, Vue } from 'vue-property-decorator';
import { WaitingRoomBranding, WaitingRoomSong } from '@/models/WaitingRoomBranding';
import ElementPlayerModal from '@/views/presentation/components/ElementPlayerModal.vue';
import { ElementListItem, Presentation } from '@/models/Presentation';
import PresentationService from '@/service/PresentationService';
import axios from 'axios';

@Component({
  components: { ElementPlayerModal }
})
export default class PreviewModal extends Vue {
  currentWaitingRoom: WaitingRoomBranding | null = null;
  currentPresentation: Presentation | null = null;
  elements: ElementListItem[] = [];
  selectedElementIdx = -1;

  audio: HTMLAudioElement | null = null;
  selectedSongs: WaitingRoomSong[] = [];
  currentSongIndex = 0;
  nextElementTimeout: any = null;

  volume = 50;

  showPreview(waitingRoom: WaitingRoomBranding) {
    if (waitingRoom.presentation) {
      this.currentWaitingRoom = waitingRoom;
      this.currentPresentation = waitingRoom.presentation;
      this.loadElements().then(() => {
        this.selectedElementIdx = 0;
        this.$nextTick(() => {
          this.$bvModal.show('element-player-modal');
          this.prepareMusic();
          this.scheduleNext();
        });
      });
    }
  }

  async loadElements() {
    if (this.currentPresentation) {
      this.elements = (await PresentationService.getElements(this.currentPresentation.id))
        .map(PresentationService.elementToListItem);
    }
  }

  prepareMusic() {
    if (!this.currentWaitingRoom) return;

    this.selectedSongs = [
      ...(this.currentWaitingRoom.predefinedMusic?.filter(song => song.selected) || []),
      ...(this.currentWaitingRoom.customMusic?.filter(song => song.selected) || [])
    ];

    if (this.selectedSongs.length > 0) {
      this.currentSongIndex = 0;
      this.playSong(this.selectedSongs[this.currentSongIndex]);
    }
  }

  playSong(song: WaitingRoomSong, start = 0) {
    if (this.audio) {
      this.audio.pause();
      this.audio.src = '';
    }

    if (!this.currentWaitingRoom) return;

    this.audio = new Audio(axios.defaults.baseURL + '/waiting_room_branding/' +
      (song.id ? this.currentWaitingRoom.id + '/music/' : 'music/') + song.filename);
    this.audio.play();
    this.audio.currentTime = start;
    this.audio.onended = () => this.nextSong();
  }

  nextSong() {
    if (this.selectedSongs.length === 0) return;
    this.currentSongIndex = (this.currentSongIndex + 1) % this.selectedSongs.length;
    this.playSong(this.selectedSongs[this.currentSongIndex]);
  }

  get totalSongsDuration() {
    return this.selectedSongs.reduce((acc, song) => acc + song.duration, 0);
  }

  scheduleNext() {
    if (!this.currentWaitingRoom) return;
    if (this.nextElementTimeout) clearTimeout(this.nextElementTimeout);

    const element = this.elements[this.selectedElementIdx];
    let duration = this.currentWaitingRoom?.defaultDuration * 1000;
    if (element.type === 'VIDEO' || element.type === 'WEBVIDEO') {
      if (element.entity.duration) {
        duration = element.entity.duration * 1000;
      }
      this.audio?.pause();
    } else {
      if (this.audio?.paused) {
        this.audio?.play();
      }
    }

    this.nextElementTimeout = setTimeout(() => this.next(), duration);
  }

  setVolume() {
    if (this.audio) {
      this.audio.volume = this.volume / 100;
    }
  }

  nextClicked() {
    if (this.selectedElementIdx === this.elements.length - 1) {
      return;
    }
    this.selectedElementIdx++;
    this.syncMusicToTime();
    this.scheduleNext(); // Sicherstellen, dass die automatische Fortsetzung geplant wird
  }

  prevClicked() {
    if (this.selectedElementIdx === 0) {
      return;
    }
    this.selectedElementIdx--;
    this.syncMusicToTime();
    this.scheduleNext();
  }

  syncMusicToTime() {
    if (!this.currentWaitingRoom || this.selectedSongs.length === 0) return;

    // Berechne die gesamte vergangene Zeit
    let elapsedTime = this.elements
      .slice(0, this.selectedElementIdx)
      .reduce((acc, el) => {
        if (el.type === 'VIDEO' || el.type === 'WEBVIDEO') {
          return acc; // Videos haben eigene Länge -> nicht zur Musikzeit hinzufügen
        }
        return acc + (this.currentWaitingRoom?.defaultDuration || 0);
      }, 0);

    if (this.totalSongsDuration > 0) {
      elapsedTime %= this.totalSongsDuration;
    }

    let songIndex = 0;
    while (elapsedTime > this.selectedSongs[songIndex].duration) {
      elapsedTime -= this.selectedSongs[songIndex].duration;
      songIndex = (songIndex + 1) % this.selectedSongs.length;
    }

    console.log('songIndex', songIndex, 'elapsedTime', elapsedTime);

    if(this.currentSongIndex === songIndex && this.audio) {
      this.audio.currentTime = elapsedTime;
    } else {
      this.currentSongIndex = songIndex;
      this.playSong(this.selectedSongs[songIndex], elapsedTime);
    }

  }

  next() {
    if (this.selectedElementIdx < this.elements.length - 1) {
      this.selectedElementIdx++;
    } else {
      this.selectedElementIdx = 0;
      this.currentSongIndex = 0;
      if (this.selectedSongs.length > 0) {
        this.playSong(this.selectedSongs[this.currentSongIndex]);
      }
    }
    this.scheduleNext();
  }

  clear() {
    this.currentWaitingRoom = null;
    this.currentPresentation = null;
    this.elements = [];
    this.selectedElementIdx = -1;
    if (this.nextElementTimeout) clearTimeout(this.nextElementTimeout);
    if (this.audio) {
      this.audio.pause();
      this.audio.src = '';
    }
    this.selectedSongs = [];
    this.currentSongIndex = 0;
    console.log('clear');
  }
}

import {
  CreateSurveyElement,
  Download,
  DownloadListItem,
  Element,
  ElementForm,
  ElementListItem,
  ImportProcess,
  IndexForm,
  OEmbedResponse,
  Presentation,
  PresentationForm,
  PresentationPageable,
  PresentationTableItem,
  SurveyElement,
  WebVideoElement,
  WebVideoElementForm
} from '@/models/Presentation';
import axios from "axios";
import qs from "qs";

export default {
  // GET
  getPresentations(
    page: number,
    size: number,
    order: string,
    direction: 'ASC' | 'DESC',
    search?: string
  ): Promise<PresentationPageable> {
    return axios
      .get("/presentation", {
        params: {page, size, order, direction, search}
      })
      .then(res => res.data);
  },
  getPresentation(id: number | string): Promise<Presentation> {
    return axios
      .get("/presentation/" + id)
      .then(res => res.data);
  },
  getElements(presentationId: number | string): Promise<Element[]> {
    return axios
      .get(`/presentation/${presentationId}/element`)
      .then(res => res.data)
  },
  getYouTubeOEmbed(url: string): Promise<OEmbedResponse> {
    return axios
      .get("https://www.youtube.com/oembed", {
        params: {url},
        transformRequest: (data: any, headers: any) => {
          delete headers.common.Authorization;
          delete headers.common['X-Client-Source'];
          return data;
        }
      })
      .then(res => res.data);
  },
  getVimeoOEmbed(url: string): Promise<OEmbedResponse> {
    return axios
      .get("https://vimeo.com/api/oembed.json", {
        params: {url},
        transformRequest: (data: any, headers: any) => {
          delete headers.common.Authorization;
          delete headers.common['X-Client-Source'];
          return data;
        }
      })
      .then(res => res.data);
  },
  getDownloads(presentationId: number | string): Promise<Download[]> {
    return axios
      .get(`/presentation/${presentationId}/download`)
      .then(res => res.data);
  },
  download(presentationId: number | string, uuid: string): Promise<Blob> {
    return axios
      .get(`/presentation/${presentationId}/download/${uuid}`, {responseType: 'blob'})
      .then(res => res.data);
  },
  getImportProcesses(presentationId: number | string): Promise<ImportProcess[]> {
    return axios
      .get(`/presentation/${presentationId}/processes`)
      .then(res => res.data.map((process: ImportProcess) => {
        if (process.pptxConvertStatus) process.pptxConvertStatusParsed = JSON.parse(process.pptxConvertStatus);
        return process;
      }));
  },

  //POST
  createPresentation(): Promise<Presentation> {
    return axios
      .post("/presentation")
      .then(res => res.data);
  },
  createWebVideoElement(presentationId: number | string, form: WebVideoElementForm): Promise<WebVideoElement> {
    return axios
      .post(`/presentation/${presentationId}/element/web-video`, {...form})
      .then(res => res.data);
  },
  createSurveyFormElement(presentationId: number | string, surveyId: number | string): Promise<Element> {
    return axios
      .post(`/presentation/${presentationId}/element/survey-form/${surveyId}`)
      .then(res => res.data);
  },
  createSpontaenousSurveyElement(presentationId: number | string, survey: CreateSurveyElement): Promise<SurveyElement> {
    return axios
      .post(`/presentation/${presentationId}/element/survey`, survey)
      .then(res => res.data);
  },
  editSpontaneousSurveyElement(presentationId: number | string, survey: CreateSurveyElement, surveyId: number | string): Promise<SurveyElement> {
    return axios
      .put(`/presentation/${presentationId}/element/survey/${surveyId}`, survey)
      .then(res => res.data);
  },
  // TODO: support multiple documents
  // This endpoint returns the processId of the conversion process
  uploadDocuments(
    presentationId: number | string,
    file: File,
    controller: AbortController,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): Promise<number> {
    const form = new FormData();
    form.append('file', file, file.name);
    return axios
      .post(`/presentation/${presentationId}/element/upload-documents`, form, {
        headers: {'Content-Type': 'multipart/form-data'},
        signal: controller.signal,
        onUploadProgress
      })
      .then(res => res.data);
  },
  // TODO: support multiple files
  uploadFiles(
    presentationId: number | string,
    file: File,
    controller: AbortController,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
  ): Promise<Download> {
    const form = new FormData();
    form.append('file', file, file.name);
    return axios
      .post(`/presentation/${presentationId}/download/upload-files`, form, {
        headers: {'Content-Type': 'multipart/form-data'},
        signal: controller.signal,
        onUploadProgress
      })
      .then(res => res.data);
  },

  // PUT
  updatePresentation(form: PresentationForm): Promise<Presentation> {
    return axios
      .put(`/presentation/${form.id}`, form)
      .then(res => res.data);
  },
  updateElement(form: ElementForm): Promise<Element> {
    return axios
      .put(`/presentation/${form.presentationId}/element/${form.id}`, form)
      .then(res => res.data);
  },
  updateElementIndexes(presentationId: number | string, idxList: IndexForm[]): Promise<Element[]> {
    return axios
      .put(`/presentation/${presentationId}/element/update-index`, idxList)
      .then(res => res.data);
  },
  updateDownloadIndexes(presentationId: number | string, idxList: IndexForm[]): Promise<Download[]> {
    return axios
      .put(`/presentation/${presentationId}/download/update-index`, idxList)
      .then(res => res.data);
  },
  closeImportProcess(presentationId: number | string, processId: number | string): Promise<void> {
    return axios
      .put(`/presentation/${presentationId}/closeProcess/${processId}`)
      .then(res => res.data);
  },

  // DELETE
  deletePresentation(id: number | string): Promise<void> {
    return axios
      .delete("/presentation/" + id)
      .then();
  },
  deleteSelectedPresentations(ids: (number | string)[]): Promise<void> {
    return axios
      .delete("/presentation", {
        params: {ids: ids},
        paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
      })
      .then();
  },
  deleteElement(presentationId: number | string, id: number | string) {
    return axios
      .delete(`/presentation/${presentationId}/element/${id}`)
      .then();
  },
  deleteSelectedElements(presentationId: number | string, ids: (number | string)[]) {
    return axios
      .delete(`/presentation/${presentationId}/element`, {
        params: {ids: ids},
        paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
      })
      .then();
  },
  deleteDownload(presentationId: number | string, id: number | string) {
    return axios
      .delete(`/presentation/${presentationId}/download/${id}`)
      .then();
  },
  deleteSelectedDownloads(presentationId: number | string, ids: (number | string)[]) {
    return axios
      .delete(`/presentation/${presentationId}/download`, {
        params: {ids: ids},
        paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
      })
      .then();
  },

  //UTILS
  presentationToTableItem(presentation: Presentation): PresentationTableItem {
    return {
      id: presentation.id,
      selected: false,
      thumbnailUrl: presentation.thumbnailUrl,
      title: presentation.title,
      dateUpdated: presentation.dateUpdated,
      dateCreated: presentation.dateCreated,
      elementCount: presentation.elementCount,
      hasAnimations: presentation.hasAnimations,
      isGlobal: true,
      waitingRoomBrandingCount: presentation.waitingRoomBrandingCount
    } as PresentationTableItem;
  },
  presentationToForm(presentation: Presentation): PresentationForm {
    return {
      id: presentation.id,
      title: presentation.title,
      isGlobal: true,
      elementCount: presentation.elementCount,
      hasAnimations: presentation.hasAnimations,
      downloadCount: presentation.downloadCount
    };
  },
  oEmbedResponseToForm(response: OEmbedResponse, form:  WebVideoElementForm): WebVideoElementForm {
    return {
      ...form,
      videoSource: response.provider_name.toLocaleLowerCase() === 'youtube' ? 'YOUTUBE' : 'VIMEO',
      thumbnailUrl: response.thumbnail_url,
      name: response.title
    }
  },
  elementToListItem(element: Element): ElementListItem {
    return {
      id: element.id,
      idx: element.idx,
      name: element.name,
      type: element.type,
      thumbnailUrl: element.thumbnailUrl,
      notes: element.notes ? element.notes : '',
      pptxProcessId: element.pptxProcessId,
      slideNumber: element.slideNumber,
      isAnimated: element.isAnimated,
      totalAnimationSteps: element.totalAnimationSteps,
      selected: false,
      entity: element
    }
  },
  elementListItemToForm(element: ElementListItem, presentationId: number): ElementForm {
    return {
      id: element.id,
      presentationId: presentationId,
      name: element.name,
      notes: element.notes,
      thumbnailUrl: element.thumbnailUrl,
    }
  },
  downloadToListItem(download: Download): DownloadListItem {
    return {
      id: download.id,
      idx: download.idx,
      presentationId: download.presentationId,
      fileName: download.fileName,
      fileType: download.fileType,
      fileSize: download.fileSize,
      thumbnailUrl: download.thumbnailUrl,
      hasThumbnail: download.hasThumbnail,
      uuid: download.uuid,
      selected: false
    }
  },
  getImageURL(elementId: number, presentationId: number): string {
    return axios.defaults.baseURL + '/presentation/' + presentationId + '/image/' + elementId + '/image.webp';
  },
  getVideoUrl(elementId: number, presentationId: number): string {
    return axios.defaults.baseURL + '/presentation/' + presentationId + '/video/' + elementId + '/video.mp4';
  },
  getPdfUrl(presentationId: number, pdfProcessId: number): string {
    return axios.defaults.baseURL + '/presentation/' + presentationId + '/pdf/pdfs/' + pdfProcessId + '/pdf.pdf';
  }
}
